import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {TranslateService} from 'src/app/service/translate.service';
import {Constants} from 'src/app/service/models/enum/constants.enum';
import {Items} from 'src/app/service/models/Items';
import {DisplayManagerService} from 'src/app/service/display-manager.service';
import {Account} from "../../../../../service/models/Account";
import {AccountSummary} from "../../../../../service/models/AccountSummary";
import {CredentialService} from "../../../../../service/credential.service";
import {Subject} from "rxjs";
import {TapNpayService} from "@shared/tap-n-pay/tap-n-pay.service";
import {tap} from "rxjs/operators";
import {environment} from "../../../../../../environments/environment";


@Component({
  selector: 'app-account-summary-items-list',
  templateUrl: './account-summary-items-list.component.html',
  styleUrls: ['./account-summary-items-list.component.scss']
})
export class AccountSummaryItemsListComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  @Input() account: Account;
  @Input() items: AccountSummary['items'];
  @Output() viewItem = new EventEmitter();
  @Output() addPayment = new EventEmitter();
  @Output() generateCcLink = new EventEmitter();

  tapNpayFeatureStatus$ = this.tapNpayService.tapNpayFeatureStatus$;
  setupPageUrl = environment.nbDashboard + '/integrations/tap-n-pay';

  constructor(
      public translateService: TranslateService,
      private displayManagerService: DisplayManagerService,
      private credentialService: CredentialService,
      private tapNpayService: TapNpayService
  ) {}

  getAmount(amount: any) {
    if (Number(amount) === 0) { return "-"; }
    return amount;
  }

  getToolTipForAccountItem(item: Items) {
    if (item.itemtype === Constants.LINE_ITEM_PAYMENT) {
      return this.translateService.translate('accountSummary', 'lineItemPaymentTooltip');
    } else if (item.itemtype === Constants.LINE_ITEM_CAPS_EXTRA) {
      return this.translateService.translate('accountSummary', 'lineItemCapsExtraTooltip');
    } else {
      return this.translateService.translate('accountSummary', 'lineItemTooltip');
    }
  }

  isMobile() {
    return this.displayManagerService.isMobile();
  }

  getBookingIdFromItems(items: AccountSummary['items']) {
    const bookingItems = items.filter(item => item.itemtype === Constants.LINE_ITEM_BOOKING);
    const lastItemIndex = bookingItems.length - 1;
    return bookingItems[lastItemIndex]?.itemid || null
  }

  getCurrency() {
    return this.credentialService.getCurrency;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
