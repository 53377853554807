import {Inject, Injectable} from "@angular/core";
import {BehaviorSubject, firstValueFrom, map, of, Subject, take, timeout, timer} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {NgbActiveModal, NgbModal, NgbModalRef,} from "@ng-bootstrap/ng-bootstrap";
import {
  IsPaymentTapBTPayload,
  IsPaymentTapBTresponse,
  IsPaymentTapEnabledNBresponse,
  IsPaymentTapResponse,
  IsReversalAllowedNBresponse,
  PaymentTapFeatureStatus, PaymentTapFeatureStatusRS,
  TAP_N_PAY_CONFIGURATION_TOKEN,
  TAP_N_PAY_HTTP_TOKEN,
  TapNpayAuth,
  TapNpayBookingDetails,
  TapNpayConfiguration,
  TapNpayDeviceBT,
  TapNpayDeviceNB,
  TapNpayDevicesBTresponse,
  TapNpayDevicesNBresponse,
  TapNpayDevicesResponse,
  TapNpayHttpClient,
  tapNpayModalMessageOptions,
  tapNpayModalOptions,
  TapNpayPaymentDetails,
  TapNpayProcessPaymentBTresponse,
  TapNpayProcessPaymentNBresponse,
  TapNpayProcessPaymentResponse,
  TapNpayProcessTransaction,
  TapNpayQueryMessage,
  TapNpaySource,
  TapNpaySources,
  TapNpayStart,
  TapNpayStatus,
  TapNpayStatuses,
  TapNpayStatusPayloadBT,
  TapNpayStatusPayloadNB,
  TapNpayStatusResponseBT,
  TapNpayStatusResponseNB,
  TapNpayTransactionBTpayload,
  TapNpayTransactionRequestNBpayload,
  TapNpayWebsocketMessage,
  TapNpayWebsocketMessageBT
} from "./tap-n-pay.class";
import {TapNpayModalAmountComponent} from "./modals/tap-n-pay-modal-amount/tap-n-pay-modal-amount.component";
import {TapNpayModalSpinnerComponent} from "./modals/tap-n-pay-modal-spinner/tap-n-pay-modal-spinner.component";
import {TapNpayModalDevicesComponent} from "./modals/tap-n-pay-modal-devices/tap-n-pay-modal-devices.component";
import {TapNpayModalApprovedComponent} from "./modals/tap-n-pay-modal-approved/tap-n-pay-modal-approved.component";
import {TapNpayModalDeclinedComponent} from "./modals/tap-n-pay-modal-declined/tap-n-pay-modal-declined.component";
import {TapNPayModalMessageComponent} from "./modals/tap-n-pay-modal-message/tap-n-pay-modal-message.component";
import {catchError} from "rxjs/operators";


const TIMEOUT_MILLISECONDS = 120000;
const TIMEOUT_SECONDS = 120;

@Injectable({providedIn: 'root'})
export class TapNpayService {

  toastNotification$ = new Subject<{ type: "approved" | "declined"; message: string; }>();
  transactionSpinnerModalRef!: NgbModalRef;

  private isTapNpayEnabled = new BehaviorSubject<boolean>(false);
  isTapNpayEnabled$ = this.isTapNpayEnabled.asObservable();

  private tapNpayFeatureStatus = new BehaviorSubject<PaymentTapFeatureStatus>({paymentTapFeatureEnabled: false, paymentTapStatusIsActive: false, paymentTapPropertyEnabled: false});
  tapNpayFeatureStatus$ = this.tapNpayFeatureStatus.asObservable();

  isModalClosed: 'true' | 'false' = "false";

  paymentDetails = new BehaviorSubject<TapNpayPaymentDetails>({bbId: 0, mode: 'payment', refundPaymentId: '', amount: 0, bookingId: 0, currencyCode: undefined});
  bookingDetails = new BehaviorSubject<TapNpayBookingDetails>({fromDate: '', toDate: '', clientFullName: '', paymentsTotal: 0, extraTotal: 0, accommodationTotal: 0, amountDue: 0});
  deviceDetailsBT = new BehaviorSubject<TapNpayDeviceBT>({deviceId: '', manufacturer: '', model: '', nickname: '', osName: '', osPlatform: '', osVersionName: '', state: ''});
  deviceDetailsNB = new BehaviorSubject<TapNpayDeviceNB>({deviceid: '', manufacturer: '', model: '', nickname: '', osname: '', osplatform: '', osversionname: '', state: ''});
  transactionEventStatus = new Subject<TapNpayStatus>();

  loginKey = "";
  route = '';
  apiUrl = '';
  urlBT = '';
  source: TapNpaySource = 'bridgeit';
  credentials: {loginkey: string} = {loginkey: ''};

  constructor(
    @Inject(TAP_N_PAY_CONFIGURATION_TOKEN) private tapNpayConfiguration: TapNpayConfiguration,
    @Inject(TAP_N_PAY_HTTP_TOKEN) private httpClient: TapNpayHttpClient,
    private ngbModal: NgbModal
  ) {
    const {source, routeNB, routeBT, environment} = this.tapNpayConfiguration;
    this.route = source === 'bridgeit' ? routeBT : routeNB;
    this.apiUrl = environment.apiUrl;
    this.urlBT = this.apiUrl + this.route;
    this.source = source;
  }

  updatePaymentDetails(paymentDetails: Partial<TapNpayPaymentDetails>) {
    const currentPaymentDetails = this.paymentDetails.value;
    this.paymentDetails.next({...currentPaymentDetails, ...paymentDetails});
  }

  updateBookingDetails(bookingDetails: Partial<TapNpayBookingDetails>) {
    const currentBookingDetails = this.bookingDetails.value;
    this.bookingDetails.next({...currentBookingDetails, ...bookingDetails});
  }

  updateDeviceDetailsBT(deviceDetailsBT: Partial<TapNpayDeviceBT>) {
    const currentDeviceDetails = this.deviceDetailsBT.value;
    this.deviceDetailsBT.next({...currentDeviceDetails, ...deviceDetailsBT});
  }

  updateDeviceDetailsNB(deviceDetailsNB: Partial<TapNpayDeviceNB>) {
    const currentDeviceDetails = this.deviceDetailsNB.value;
    this.deviceDetailsNB.next({...currentDeviceDetails, ...deviceDetailsNB});
  }

  getConfiguration() {
    return this.tapNpayConfiguration
  }

  startTapNPay({paymentDetails, bookingDetails, skipAmountModal = false, activeModal}: TapNpayStart) {
    if (this.isTapNpayEnabled.value) {
      this.isModalClosed = "false"
      this.updatePaymentDetails(paymentDetails);
      if (bookingDetails) { this.updateBookingDetails(bookingDetails) }
      !skipAmountModal ? this.openAmountModal() : this.getDevices(activeModal);
    }
  }

  openSpinnerModal(parameters: { message: string }) {
    const spinnerModalRef = this.ngbModal.open(TapNpayModalSpinnerComponent, tapNpayModalOptions);
    spinnerModalRef.componentInstance.message = parameters.message;
    return spinnerModalRef;
  }

  async checkIsTapNpayEnabled({bbid, loginkey}: TapNpayAuth) {
    return  this.tapNpayConfiguration.source === TapNpaySources.BRIDGEIT ?
    await this.checkIsTapNpayEnabledBT({bbid, loginkey}) :
    await this.checkIsTapNpayEnabledNB(bbid)

  }

  async checkTapNpayFeatureStatus({bbid, loginkey}: TapNpayAuth) {
    return  this.tapNpayConfiguration.source === TapNpaySources.BRIDGEIT ?
        await this.checkTapNpayFeatureStatusBT({bbid, loginkey}) :
        await this.checkTapNpayFeatureStatusNB(bbid)
  }

  async checkIsTapNpayEnabledBT({bbid, loginkey}: TapNpayAuth) {
    this.credentials = {loginkey};

    const httpMethod = this.getHttpMethod("post");

    const messagename: TapNpayQueryMessage = "PaymentTapEnabledRQ";
    const payload: IsPaymentTapBTPayload = {messagename, credentials: this.credentials, bbid};
    const body = this.getBodyWithHeaders<IsPaymentTapBTPayload>(payload);


    const response = await firstValueFrom(this.httpClient[httpMethod]<IsPaymentTapBTresponse>(this.urlBT, body))

    return this.handleIsTapEnabledResponse(response);
  }

  async checkIsTapNpayEnabledNB(bbid: number) {
    const httpMethod = this.getHttpMethod('get');
    const endpoint = `payment-tap-enabled?bbId=${bbid}`;
    const url = this.apiUrl + this.route + endpoint;

    const response = await firstValueFrom(this.httpClient[httpMethod]<IsPaymentTapEnabledNBresponse>(url))
    return this.handleIsTapEnabledResponse(response)
  }

  async checkTapNpayFeatureStatusBT({bbid, loginkey}: TapNpayAuth) {
    this.credentials = {loginkey};

    const httpMethod = this.getHttpMethod("post");

    const messagename: TapNpayQueryMessage = "PaymentTapFeatureStatusRQ";
    const payload: IsPaymentTapBTPayload = {messagename, credentials: this.credentials, bbid};
    const body = this.getBodyWithHeaders<IsPaymentTapBTPayload>(payload);

    const response = await firstValueFrom(this.httpClient[httpMethod]<PaymentTapFeatureStatusRS>(this.urlBT, body)
        .pipe(map((response) => response.data)));

    return this.handleTapNpayFeatureStatusResponse(response);
  }

  async checkTapNpayFeatureStatusNB(bbid: number) {
    const httpMethod = this.getHttpMethod('get');
    const endpoint = `payment-tap-feature-status?bbId=${bbid}`;
    const url = this.apiUrl + this.route + endpoint;

    const response = await firstValueFrom(this.httpClient[httpMethod]<PaymentTapFeatureStatus>(url))
    return this.handleTapNpayFeatureStatusResponse(response)
  }

  checkIsReversalAllowed(paymentid: number, amount: number) {
    const httpMethod = this.getHttpMethod('post');
    const endpoint = `request-refund`;
    const url = this.apiUrl + this.route + endpoint;

    return this.httpClient[httpMethod]<IsReversalAllowedNBresponse>(url, {paymentid, amount});
  }

  handleIsTapEnabledResponse(response: IsPaymentTapResponse) {
    let isTapNpayEnabled = false;

    if (this.tapNpayConfiguration.source === TapNpaySources.BRIDGEIT) {
      const actualResponse = response as IsPaymentTapBTresponse;
      isTapNpayEnabled = actualResponse.data.paymentTapEnabled;
    }

    if (this.tapNpayConfiguration.source === TapNpaySources.NIGHTSBRIDGE) {
      const actualResponse = response as IsPaymentTapEnabledNBresponse;
      isTapNpayEnabled = actualResponse.paymenttapenabled;
    }

    this.isTapNpayEnabled.next(isTapNpayEnabled);
    return isTapNpayEnabled ? this.toastNotification$ : null;
  }

  handleTapNpayFeatureStatusResponse(response: PaymentTapFeatureStatus) {
    let paymentTapFeatureStatus:PaymentTapFeatureStatus;
    if (this.tapNpayConfiguration.source === TapNpaySources.BRIDGEIT) {
      paymentTapFeatureStatus = response as PaymentTapFeatureStatus;
    }

    if (this.tapNpayConfiguration.source === TapNpaySources.NIGHTSBRIDGE) {
      paymentTapFeatureStatus = response as PaymentTapFeatureStatus;
    }

    this.tapNpayFeatureStatus.next(paymentTapFeatureStatus);
    return paymentTapFeatureStatus ? this.toastNotification$ : null;
  }

  openAmountModal() {
    this.ngbModal.open(TapNpayModalAmountComponent, tapNpayModalOptions);
  }

  getDevices(activeModal?: NgbActiveModal) {
    if (activeModal) { activeModal.close(); }
    const spinnerModal = this.openSpinnerModal({message: "Fetching devices..."});
    const paymentDetails = this.paymentDetails.value;

    return this.tapNpayConfiguration.source === TapNpaySources.BRIDGEIT ?
    this.getDevicesBT({spinnerModal, paymentDetails}) :
    this.getDevicesRequestNB({spinnerModal, paymentDetails});
  }

 async getDevicesBT({spinnerModal, paymentDetails}: {spinnerModal: NgbModalRef, paymentDetails: TapNpayPaymentDetails}) {
    const httpMethod = this.getHttpMethod("post");
    const bbid = paymentDetails.bbId || 0;
    const messagename: TapNpayQueryMessage = "DeviceManagementRQ";
    const payload: IsPaymentTapBTPayload = {messagename, credentials: this.credentials, bbid};
    const body = this.getBodyWithHeaders<IsPaymentTapBTPayload>(payload);

    const response = await firstValueFrom(
      this.httpClient[httpMethod]<TapNpayDevicesBTresponse>(`${this.urlBT}?skipInterceptor=true`, body)
        .pipe(catchError((error) => this.handleDeviceResponseError(error, spinnerModal)))
      );

   if (response?.success) {
     this.handleDevicesResponse(response, spinnerModal);
   } else {
     this.handleDeviceResponseError(response?.error, spinnerModal)
   }
  }

  async getDevicesRequestNB({spinnerModal, paymentDetails}: {spinnerModal: NgbModalRef, paymentDetails: TapNpayPaymentDetails}) {
    const httpMethod = this.getHttpMethod('get');
    const bbid = paymentDetails.bbId || 0;
    const endpoint = `device-management/devices?bbId=${bbid}`;
    const url = this.apiUrl + this.route + endpoint;

    const response = await firstValueFrom(this.httpClient[httpMethod]<TapNpayDevicesResponse>(url)
      .pipe(catchError((error) => this.handleDeviceResponseError(error, spinnerModal))
    ));

    if (response) {
      this.handleDevicesResponse(response, spinnerModal);
    }
  }

  handleDevicesResponse(response: TapNpayDevicesResponse, spinnerModal: NgbModalRef) {
    spinnerModal.close();

    let devicesNB: TapNpayDeviceNB[] = [];
    let devicesBT: TapNpayDeviceBT[] = [];

    if (this.tapNpayConfiguration.source === TapNpaySources.BRIDGEIT) {
      const actualResponse = response as TapNpayDevicesBTresponse;
      devicesBT = actualResponse.data.devices;
    }

    if (this.tapNpayConfiguration.source === TapNpaySources.NIGHTSBRIDGE) {
      const actualResponse = response as TapNpayDevicesNBresponse;
      devicesNB = actualResponse.devices;
    }


    this.openDevicesModal({devicesBT, devicesNB});
  }

  handleDeviceResponseError(error: any, spinnerModal: NgbModalRef) {
    console.error(error);
    const confirmModal = this.ngbModal.open(TapNPayModalMessageComponent, tapNpayModalMessageOptions);
    confirmModal.componentInstance.title = 'Tap & Pay';
    confirmModal.componentInstance.subtitle = 'Service Unavailable';
    confirmModal.componentInstance.message = 'Oops, something has gone wrong. Please try again later.';
    confirmModal.componentInstance.primaryText = 'Close';
    spinnerModal.close();
    return of(null);
  }

  openDevicesModal({devicesNB, devicesBT}: { devicesBT: TapNpayDeviceBT[], devicesNB: TapNpayDeviceNB[],  }) {
    const devicesModalRef = this.ngbModal.open(TapNpayModalDevicesComponent, tapNpayModalOptions);
    if (this.tapNpayConfiguration.source === TapNpaySources.BRIDGEIT) {
      devicesModalRef.componentInstance.devicesBT = devicesBT;
    } else {
      devicesModalRef.componentInstance.devicesNB = devicesNB;
    }
  }

  async processPayment(activeModal: NgbActiveModal) {
    activeModal.close();

    const spinnerModalRef = this.openSpinnerModal({message: "Transaction in progress..."});
    spinnerModalRef.componentInstance.confirmBeforeClose = true;
    spinnerModalRef.componentInstance.timeoutAfterLabel = '2 minutes';
    const paymentDetails = this.paymentDetails.value;
    const deviceDetailsBT = this.deviceDetailsBT.value;
    const deviceDetailsNB = this.deviceDetailsNB.value;

    spinnerModalRef.closed.pipe(take(1)).subscribe(() => {
      this.isModalClosed = "true";
    });

    this.transactionSpinnerModalRef = spinnerModalRef;

    this.tapNpayConfiguration.source === TapNpaySources.BRIDGEIT ?
    await this.processPaymentBT({deviceDetailsBT, paymentDetails, spinnerModalRef}) :
    await this.processPaymentNB({deviceDetailsNB, paymentDetails, spinnerModalRef});
  }

  async processPaymentBT({deviceDetailsBT, paymentDetails, spinnerModalRef}:{
    paymentDetails:TapNpayPaymentDetails,
    deviceDetailsBT: TapNpayDeviceBT,
    spinnerModalRef: NgbModalRef
  }) {

    const httpMethod = this.getHttpMethod("post");
    const messagename: TapNpayQueryMessage = paymentDetails.mode === 'refund' ? "RefundTapRQ" : 'PaymentTapRQ';

    let payload: TapNpayTransactionBTpayload = {
      messagename,
      credentials: this.credentials,
      bbid: paymentDetails.bbId,
      amount: paymentDetails.amount,
      source: this.source,
      bookingId: paymentDetails.bookingId,
      deviceId: deviceDetailsBT.deviceId,
    };

    const body = this.getBodyWithHeaders<TapNpayTransactionBTpayload>(payload);

    const response = await firstValueFrom(
      this.httpClient[httpMethod]<TapNpayProcessPaymentResponse>(this.urlBT, body).pipe(
        timeout(TIMEOUT_MILLISECONDS),
        catchError(() => (of({data: {paymentId: '', status: false, description: ''}})))
      )
    );


    this.handleProcessPaymentResponse({
      response,
      spinnerModalRef,
      paymentDetails,
      deviceId: deviceDetailsBT.deviceId,
      deviceid: ''
    });
  }

  async processPaymentNB({deviceDetailsNB, paymentDetails, spinnerModalRef}:{
    paymentDetails:TapNpayPaymentDetails,
    deviceDetailsNB: TapNpayDeviceNB,
    spinnerModalRef: NgbModalRef
  }) {
    if (this.paymentDetails.value) {

      let body: TapNpayTransactionRequestNBpayload = {
        bookingid: paymentDetails.bookingId,
        bbid: paymentDetails.bbId,
        amount: paymentDetails.amount,
        source: this.source,
        deviceid: deviceDetailsNB.deviceid,
        initialpaymentid: paymentDetails.refundPaymentId
      };

      const httpMethod = this.getHttpMethod("post");

      const endpoint = paymentDetails.mode === 'payment' ? `initiate-payment` : 'initiate-refund';
      const url = this.apiUrl + this.route + endpoint;

      const response = await firstValueFrom(
        this.httpClient[httpMethod]<TapNpayProcessPaymentResponse>(url, body).pipe(
          timeout(TIMEOUT_MILLISECONDS),
          catchError(() => (of({paymentid: '', status: false, description: ''})))
        )
      );

      this.handleProcessPaymentResponse({
        response,
        spinnerModalRef,
        paymentDetails,
        deviceid: deviceDetailsNB.deviceid,
        deviceId: ''
      });
    }
  }

  async handleProcessPaymentResponse({
    response,
    spinnerModalRef,
    paymentDetails
   }:{
    response: TapNpayProcessPaymentResponse,
    spinnerModalRef: NgbModalRef,
    paymentDetails: TapNpayPaymentDetails,
    deviceId: string,
    deviceid: string,
  }) {

    let status = false;
    let paymentId =  '';

    if (this.tapNpayConfiguration.source === TapNpaySources.BRIDGEIT) {
      const actualResponse = response as TapNpayProcessPaymentBTresponse;
      status = actualResponse.data.status;
      paymentId = actualResponse.data.paymentId;
    }

    if (this.tapNpayConfiguration.source === TapNpaySources.NIGHTSBRIDGE) {
      const actualResponse = response as TapNpayProcessPaymentNBresponse;
      status = actualResponse.status;
      paymentId = actualResponse.paymentid;
    }

    if (status) {

      const messagename =  'transactionhistoryrq';
      const hash = await this.createHash(paymentId.toString());

      localStorage.setItem(hash, this.isModalClosed);

      if (this.isModalClosed === "false") {
        this.checkIfModalIsClosedAfterHashIsGenerated(spinnerModalRef, hash);
      }

      spinnerModalRef.componentInstance.startTimer(TIMEOUT_SECONDS);

      const timeOutTimer$ = timer(TIMEOUT_MILLISECONDS).subscribe(() => {
        if (localStorage.getItem(hash)) {
          if (paymentDetails) {
            const {bbId} = paymentDetails;
            this.checkTransactionStatus({messagename, bbId, paymentId, hash, timeOutTimer$, spinnerModalRef});
          }
        }
      });

    } else {
      this.transactionSpinnerModalRef.close();
      if (this.isModalClosed === "true") {
        this.toastNotification$.next({type: "declined", message: "Tap & Pay declined"});
      } else {
        this.openDeclinedModal();
      }
    }

  }

  async checkTransactionStatus({messagename, credentials, source, bbId, paymentId, hash, timeOutTimer$, spinnerModalRef}: TapNpayStatusPayloadBT & {  spinnerModalRef: NgbModalRef}) {

    if (this.tapNpayConfiguration.source === TapNpaySources.BRIDGEIT) {
      this.checkTransactionStatusBT({bbId, paymentId, hash, messagename, credentials, source, timeOutTimer$, spinnerModalRef});
    } else {
      this.checkTransactionStatusNB({bbId, paymentId, hash, timeOutTimer$, spinnerModalRef})
    }
  }

  async checkTransactionStatusBT({
    bbId,
    paymentId,
    hash,
    timeOutTimer$,
    spinnerModalRef
  }: Omit<TapNpayStatusPayloadBT, 'deviceid'> & {  spinnerModalRef: NgbModalRef}) {

    const payload: Omit<TapNpayStatusPayloadBT, 'deviceid'> = {
      source: this.source,
      messagename: 'TransactionHistoryRQ',
      credentials: this.credentials,
      bbId,
      paymentId: paymentId.toString(),
      hash,
    };

    let body = this.getBodyWithHeaders<Omit<TapNpayStatusPayloadBT, 'deviceid'>>(payload);

    const httpMethod = this.getHttpMethod("post");

    const response = await firstValueFrom(
      this.httpClient[httpMethod]<TapNpayStatusResponseBT>(this.urlBT, body)
    );

    this.handleCheckStatusResponse({hash, timeOutTimer$, status: response.data.status, spinnerModalRef});
  }

  async checkTransactionStatusNB({bbId, paymentId, hash, timeOutTimer$, spinnerModalRef}: TapNpayStatusPayloadNB & {  spinnerModalRef: NgbModalRef}) {

    const httpMethod = this.getHttpMethod("get");
    const endpoint = `transaction-status?bbId=${bbId}&paymentId=${paymentId}`;
    const url = this.apiUrl + this.route + endpoint;

    const response = await firstValueFrom(
      this.httpClient[httpMethod]<TapNpayStatusResponseNB>(url)
    );

    this.handleCheckStatusResponse({hash, timeOutTimer$, status: response.status, spinnerModalRef});
  }

  handleCheckStatusResponse({hash, timeOutTimer$, status, spinnerModalRef}: TapNpayProcessTransaction & {  spinnerModalRef: NgbModalRef}) {
    const isModalClosed = localStorage.getItem(hash) as "true" | "false" | undefined;

    if (status === TapNpayStatuses.DECLINED) {
      this.transactionEventStatus.next(TapNpayStatuses.DECLINED);
      this.openDeclinedModal();
      timeOutTimer$?.unsubscribe();
      spinnerModalRef.componentInstance.timeOutTimer = null;
      localStorage.removeItem(hash);
    }

    if(status === TapNpayStatuses.CANCELLED) {
      this.transactionEventStatus.next(TapNpayStatuses.CANCELLED);
      timeOutTimer$?.unsubscribe();
      spinnerModalRef.componentInstance.timeOutTimer = null;
      if (isModalClosed === 'true') {
        this.toastNotification$.next({
          type: "declined",
          message: `Tap & Pay cancelled - Please try again`,
        });
      }

      if (isModalClosed === 'false') {
        this.openDeclinedModal('CANCELLED');
      }


      this.transactionSpinnerModalRef.close();
      localStorage.removeItem(hash);
    }

    if(status === TapNpayStatuses.FAILED) {
      this.transactionEventStatus.next(TapNpayStatuses.FAILED);
      timeOutTimer$?.unsubscribe();
      spinnerModalRef.componentInstance.timeOutTimer = null;
      if (isModalClosed === 'true') {
        this.toastNotification$.next({
          type: "declined",
          message: `Tap & Pay failed - Please try again`,
        });
      }

      if (isModalClosed === 'false') {
        this.openDeclinedModal('CANCELLED');
      }


      this.transactionSpinnerModalRef.close();
      localStorage.removeItem(hash);
    }
  }

  handleTapNpayWebsocket(data: any) {
    const transactionResponse = data as TapNpayWebsocketMessage;

    if (transactionResponse.action === "PAYMENT_TAP_STATUS") {

      const paymentId = transactionResponse.paymentId;

      this.createHash(paymentId).then((hash) => {
        this.handleTransactionStatus({hash, transactionResponse, paymentId: parseInt(paymentId)});
      });
    }
  }

  handleTransactionStatus({hash, transactionResponse, paymentId}: {
    hash: string,
    transactionResponse: TapNpayWebsocketMessage,
    paymentId: number
  }) {

    const isModalClosed = localStorage.getItem(hash) as "true" | "false" | undefined;

    if (isModalClosed === "true") {
      if (transactionResponse?.status === TapNpayStatuses.APPROVED) {
        this.transactionEventStatus.next(TapNpayStatuses.APPROVED);
        this.toastNotification$.next({
          type: "approved",
          message: `Tap & Pay approved - Payment ID: ${paymentId}`,
        });
      } else {
        this.transactionEventStatus.next(TapNpayStatuses.DECLINED);
        this.toastNotification$.next({
          type: "declined",
          message: `Tap & Pay declined - Payment ID: ${paymentId}`,
        });
      }
      localStorage.removeItem(hash);
    } else if (isModalClosed === 'false') {
      if (transactionResponse?.status === TapNpayStatuses.APPROVED) {
        this.transactionEventStatus.next(TapNpayStatuses.APPROVED);
        if (this.transactionSpinnerModalRef) {
          this.transactionSpinnerModalRef.close();
        }
        this.openApprovedModal(transactionResponse);
      } else {
        this.transactionEventStatus.next(TapNpayStatuses.DECLINED);
        if (this.transactionSpinnerModalRef) {
          this.transactionSpinnerModalRef.close();
          this.openDeclinedModal();
        } else {
          this.toastNotification$.next({
            type: "declined",
            message: `Tap & Pay declined - Payment ID: ${paymentId}`,
          });
        }
      }
      localStorage.removeItem(hash);
    }
  }

  checkIfModalIsClosedAfterHashIsGenerated(
    spinnerModalRef: NgbModalRef,
    hash: string
  ) {
    spinnerModalRef.closed.pipe(take(1)).subscribe(() => {
      localStorage.setItem(hash, this.isModalClosed);
    });
  }

  openApprovedModal(transactionDetails?: TapNpayWebsocketMessageBT) {
    if (this.transactionSpinnerModalRef) { this.transactionSpinnerModalRef.close(); }
    const approvedModalRef = this.ngbModal.open(TapNpayModalApprovedComponent, tapNpayModalOptions);
    approvedModalRef.componentInstance.tapNpayTransactionResponse = transactionDetails;
  }

  openDeclinedModal(mode: TapNpayStatus = 'DECLINED') {
    if (this.transactionSpinnerModalRef) { this.transactionSpinnerModalRef.close(); }
    const tapNpayModalDeclinedComponent = this.ngbModal.open(TapNpayModalDeclinedComponent, tapNpayModalOptions);
    tapNpayModalDeclinedComponent.componentInstance.mode = mode;
  }

  async createHash(value: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(value);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  }

  sanitizeBookingAmount(amount: string | number | undefined) {
    if (typeof amount === 'number') {
      return amount;
    } else if (typeof amount === 'string') {
      return parseFloat(amount.replace(/,/g, ''));
    } else {
      return 0;
    }
  }

  getHttpMethod(method: "get" | "post" | "put" | "delete") {
    const httpMethodMap: Record<string, string> = {
      "get": this.httpClient["get"] ? "get" : "getRequest",
      "post": this.httpClient["post"] ? "post" : "postRequest",
      "put": this.httpClient["put"] ? "put" : "putRequest",
      "delete": this.httpClient["delete"] ? "delete" : "deleteRequest"
    };

    return httpMethodMap[method];
  }

  getBodyWithHeaders<T>(payload: T) {
   return new HttpParams().set("data", JSON.stringify(payload)).set("skip", true);
  }

}
