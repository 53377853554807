import {Component, Input, OnDestroy} from '@angular/core';
import {TapNpayService} from './tap-n-pay.service';
import {PaymentTapFeatureStatus, TapNpayBookingDetails, TapNpayPaymentDetails} from "./tap-n-pay.class";
import {Subject, takeUntil} from "rxjs";

@Component({
    selector: 'app-tap-n-pay',
    styles: [`
        ::ng-deep .tap-n-pay-button {
            background-color: var(--cui-white) !important;
            color: var(--cui-primary) !important;
            border: 1px solid var(--cui-primary);

            &:hover {
                background-color: var(--cui-primary) !important;
                color: var(--cui-white) !important;
                border: 0.5px solid var(--cui-primary) !important;

                .leading-icon {
                    color: var(--cui-white) !important;
                }
            }
        }
    `],
    template: `
      <div *ngIf="paymentTapFeatureStatus.paymentTapFeatureEnabled">
        <cui-button (onClick)="initializeTapNpay()" *ngIf="paymentTapFeatureStatus.paymentTapStatusIsActive" [type]="'outlined'" [disabled]="!paymentTapFeatureStatus.paymentTapPropertyEnabled" leadingIcon="rfid" [classes]="classes + ' tap-n-pay-button tap-n-pay-button-override'">
          <span class="fs-14">Tap & Pay</span>
        </cui-button>

        <cui-button (onClick)="navigateToSetup()" *ngIf="!paymentTapFeatureStatus.paymentTapStatusIsActive" [type]="'outlined'" leadingIcon="rfid" [classes]="classes + ' tap-n-pay-button tap-n-pay-button-override'">
          <span class="fs-14">Set up your Tap & Pay</span>
        </cui-button>
      </div>
    `
})
export class TapNpayComponent implements OnDestroy {

    private destroy$ = new Subject<void>();
    @Input() disabled = false;
    @Input() classes = '';
    @Input() bookingDetails?: TapNpayBookingDetails;
    @Input() details: TapNpayPaymentDetails = {bbId: 0, amount: 0, bookingId: 0, mode: 'payment', refundPaymentId: '', currencyCode: ''};
    @Input() setupPageUrl = '';
    @Input() paymentTapFeatureStatus: PaymentTapFeatureStatus;

    constructor(
      private tapNpayService: TapNpayService
    ) {}

    initializeTapNpay() {
        const accommodationTotal = this.tapNpayService.sanitizeBookingAmount(this.bookingDetails?.accommodationTotal);
        const extraTotal = this.tapNpayService.sanitizeBookingAmount(this.bookingDetails?.extraTotal);
        const paymentsTotal = this.tapNpayService.sanitizeBookingAmount(this.bookingDetails?.paymentsTotal);

        let amountDue = 0;
        const {amount, bbId, bookingId, mode, refundPaymentId, currencyCode} = this.details;

        let fromDate = new Date().toString();
        let toDate = new Date().toString();
        let clientFullName = undefined;

        if (this.bookingDetails) {
          amountDue = Math.round(accommodationTotal + extraTotal - paymentsTotal);
          fromDate = this.bookingDetails.fromDate;
          toDate = this.bookingDetails.toDate;
          clientFullName = this.bookingDetails.clientFullName;
        }

        this.tapNpayService.startTapNPay({
          paymentDetails: {amount, bbId, bookingId, mode, refundPaymentId, currencyCode},
          bookingDetails: {amountDue, accommodationTotal, extraTotal, paymentsTotal, fromDate, toDate, clientFullName}
        })
    }

    navigateToSetup(){
      window.open(this.setupPageUrl);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
