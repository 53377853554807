import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormArray, Validators, FormGroup} from '@angular/forms';

import { cloneDeep } from 'lodash';

import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {ClientsService} from 'src/app/service/clients.service';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {TranslateService} from 'src/app/service/translate.service';
import {CredentialService} from 'src/app/service/credential.service';
import {Constants} from 'src/app/service/models/enum/constants.enum';
import {environment} from 'src/environments/environment';
import {AccountService} from '../../../service/account.service';
import {ClientEdit} from '../../../service/models/ClientEdit';
import ClientType from 'src/assets/data/clientType.json';
import {Client} from '../../../service/models/Client';
import {RateSheet} from '../../../service/models/RateSheet';
import {ClientAddr} from '../../../service/models/ClientAddr';
import {clientEditValidator} from "../../../shared/directives/clientFirstNameValidator.directive";
import {ActivatedRoute} from "@angular/router";
import {PreferencesService} from "../../../service/preferences.service";


@Component({
  selector: 'app-client-add',
  templateUrl: './client-add.component.html',
  styleUrls: ['./client-add.component.scss']
})
export class ClientAddComponent implements OnInit {
  @Input() options;

  pageData;
  returnData;
  clientObj: ClientEdit;

  INITIAL_PROCESS = true;
  focusFirstName = true;
  clientExtrasLength;
  hideClientID: boolean;
  extraInfoChanged = false;
  // showAccounts: boolean; // TODO  = !nb.hasChildView("AccountSummary");
  clienttypes = ClientType;

  clientForm: FormGroup = this.formBuilder.group(
    {
      clienttype: [''],
      title: ['', Validators.maxLength(10)],
      firstname: ['', [Validators.required, Validators.maxLength(20)]],
      surname: ['', [Validators.required, Validators.maxLength(40)]],
      phoneno: [''],
      bbclientid: [{value: 0, disabled: true}],
      datecreated: [{value: undefined, disabled: true}],
      company: ['', [Validators.required, Validators.maxLength(80)]],
      email: ['', Validators.email],
      bbratesheetid: [0],
      notes: [''],
      clientaddr: this.formBuilder.group({
        vatno: [''],
        add1: ['', [Validators.maxLength(200)]],
        add2: ['', [Validators.maxLength(200)]]
      }),
      clientextras: this.formBuilder.array([])
    }, {validators: clientEditValidator}
  );

  surnameValid: boolean = false;
  firstnameValid: boolean = false;
  companyValid: boolean = false;

  latestCreditNote;
  latestInvoice;

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };
  activeID: any;
  pdfViewerActive;
  invoiceTemplatePreference: string;
  constants;
  clientAttributes;

  constructor(private pageRouteDataService: PageRouteDataService,
    private pageRouteService: PageRouteService,
    public clientsService: ClientsService,
    private datelibraryService: DatelibraryService,
    private confirmationService: ConfirmationService,
    public translateService: TranslateService,
    private credentialService: CredentialService,
    private accountService: AccountService,
    private preferencesService: PreferencesService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
    this.constants = Constants;
    this.clientAttributes = ClientEdit.getClientAttributes(this.translateService);
  }

  ngOnInit(): void {
    this.clientObj = new ClientEdit(this.translateService);
    this.clientObj.clienttypes = ClientType;

    this.mapClientTypes().then((result) => {
      this.clientsService.addclienttypes = result;
    });

    this.pageData = this.pageRouteDataService.getData();
    this.returnData = this.pageRouteDataService.getReturnData();

    this.handleIncomingDataMerging();
    this.clientObj.client.clientid = this.options?.clientid || Number(this.route.children[0].children[0].snapshot.paramMap.get("clientId")) || 0;   
    this.fetchData(this.INITIAL_PROCESS);

    this.clientForm.valueChanges.subscribe(value => {
      this.debounce(this.getFormValues)();
    });

    this.pdfViewerActive = this.credentialService.getCurrentUser.value.pdfViewerActive;
    this.preferencesService.getPreferences().subscribe((response: any) => {
      this.invoiceTemplatePreference = response.data.preferences.useexcelformatforinvoices ? Constants.EXCEL : response.data.preferences.usepdfformatforinvoices ? Constants.PDF : Constants.NOSELECTION;
    });
  }

  mapClientTypes() {
    return Promise.all(this.clienttypes.map(async type => {
      const translationName = await this.translateService.translate('clientType', type.name);

      return {
        value: type.value,
        name: translationName
      }
    }));
  }

  get clientextras() {
    return this.clientForm.get('clientextras') as FormArray;
  }

  get clientaddr() {
    return this.clientForm.get('clientaddr') as FormGroup;
  }

  close() {
    this.pageRouteService.close();
  }

  back(returnData?) {
    if (this.options && this.options?.roomname !== undefined) {
      if (!returnData) {
        returnData = {};
      }
      returnData.roomname = this.options?.roomname;
    }
    if (this.options && this.options?.clientid !== undefined) {
      if (!returnData) {
        returnData = this.clientObj.client;
      }
    }

    this.pageRouteService.back(returnData);
  }

  getCurrentUrl() {
    return (this.clientObj.client.clientid) ? 'clients/edit/' + this.clientObj.client.clientid : 'clients/add';
  }

  loadClientExtras() {
    const control = this.clientextras;
    if (this.clientObj.clientextras.length) {
      control.clear();
      this.clientObj.clientextras.forEach(x => {
        control.push(this.patchClientExtra(x.fieldtype, x.fieldvalue, x.bbclientextraid));
      });
    } else {
      //empty, add first entry
      control.clear();
      control.push(this.patchClientExtra('', '', 0));
    }
  }

  patchClientExtra(type, value, id) {
    return this.formBuilder.group({
      fieldtype: [type],
      fieldvalue: [value],
      bbclientextraid: [id]
    })
  }

  removeClientExtra(index) {
    this.clientForm.markAsDirty();
    if (index === 0) {
      this.clientextras.setControl(index, this.patchClientExtra('', '', index.bbclientextraid));
    } else {
      this.clientextras.removeAt(index);
    }
  }

  addClientExtra() {
    this.clientForm.markAsDirty();
    this.clientextras.push(this.patchClientExtra('', '', 0));
  }

  setFormValues() {
    if (this.clientObj?.client) {
      this.clientForm.patchValue({
        clienttype: this.clientObj.client.clienttype,
        title: this.clientObj.client.title,
        firstname: this.clientObj.client.firstname,
        surname: this.clientObj.client.surname,
        phoneno: this.clientObj.client.phoneno,
        bbclientid: this.clientObj.client.bbclientid,
        datecreated: this.clientObj.client.datecreated,
        company: this.clientObj.client.company,
        email: this.clientObj.client.email,
        bbratesheetid: this.clientObj.client.bbratesheetid,
        notes: this.clientObj.client.notes
      });
      this.calculateBalanceOnAccount();
    }
    if (this.clientObj?.clientaddr) {
      this.clientForm.patchValue({
        clientaddr: {
          vatno: this.clientObj.clientaddr.vatno,
          add1: this.clientObj.clientaddr.add1,
          add2: this.clientObj.clientaddr.add2
        }
      });
    }
    if (this.clientObj?.clientextras) {
      this.loadClientExtras();
    }
  }

  getFormValues() {
    this.validateName();
    let client = this.clientForm.value;
    delete client.clientaddr;
    delete client.clientextras;
    const clientaddr = this.clientaddr.value;
    const clientextras = this.clientextras.value;
    this.clientObj.client = {...this.clientObj.client, ...client};
    this.clientObj.clientaddr = {...this.clientObj.clientaddr, ...clientaddr};
    this.clientObj.clientextras = clientextras;
  }

  getAccountID(accountid) {
    let account = this.clientObj.accounts.filter((account) => {
      return account.accountid === accountid;
    })
    return account.length > 0 ? account[0].bbaccountid : accountid;
  }

  fetchData(extraProcess?) {
    let self = this;
    this.clientsService.getClientData(this.clientObj.client).then((response) => {
      this.clientObj = response.data;
      this.processData(response.data);
      this.setFormValues();
      if (extraProcess) {
        self.handleUpdateRefreshedData();
      }
    });
  }

  saveClient() {
    this.getFormValues();
    this.clientForm.markAllAsTouched();
    this.validateName();
    if (this.clientForm.pristine && this.clientObj.client.clientid !== 0) {
      this.back();
    } else if (this.clientForm.valid) {
      this.clientObj.client.clientid = this.clientObj.client.clientid !== undefined ? this.clientObj.client.clientid : 0;
      this.clientsService.saveClient(this.clientObj.client, this.clientObj.clientextras, this.clientObj.clientaddr).then((response: any) => {
        if (response.data.clientsaved === this.clientObj.client.clientid || this.clientObj.client.clientid === 0) {
          this.clientObj.client.newclient = (this.clientObj.client.clientid === 0);
          this.clientObj.client.fullname = this.accountService.getClientFullName(this.clientObj.client);
          this.clientObj.client.clientid = response.data.clientsaved; // Give them the new ClientID
          this.clientObj.client.bbclientid = response.data.bbclientid;
          this.back(this.clientObj.client);
        }
      });
    }
  }

  handleIncomingDataMerging(): void {
    if (this.pageData && !this.options) {
      this.options = this.pageData;
    }

    if (this.returnData && this.options) {
      this.options = {...this.options, ...this.returnData};
    }

    if (!this.options) {
      this.back();
    }

    if (this.options?.formValues) {
      this.handleCachedFormValues();
    }
  }

  private handleCachedFormValues(): void {
    const formValues = this.options.formValues;
    
    if (!this.clientObj) {
      this.clientObj = new ClientEdit(this.translateService);
    }

    const clientFields = [
      'clienttype', 'title', 'firstname', 'surname', 'phoneno',
      'bbclientid', 'company', 'email', 'bbratesheetid', 'notes'
    ];

    clientFields.forEach(field => {
      if (formValues[field] !== undefined) {
        this.clientObj.client[field] = formValues[field];
      }
    });

    this.clientObj.client.fullname = this.accountService.getClientFullName(this.clientObj.client);

    if (formValues.datecreated !== undefined) {
      this.clientObj.dateCreated = formValues.datecreated;
    }

    const addrFields = ['vatno', 'add1', 'add2'];
    addrFields.forEach(field => {
      if (formValues[field] !== undefined) {
        this.clientObj.clientaddr[field] = formValues[field];
      }
    });
  }

  processData(data: any): void {
    const processedData = cloneDeep(data);
    
    if (this.options?.clientid > 0) {
      this.clientObj = {
        ...processedData,
        dateCreated: this.datelibraryService.getLocalDateTime(processedData.client.datecreated)
      };
      this.calculateBalanceOnAccount();

      if (processedData.client) {
        processedData.client.fullname = this.accountService.getClientFullName(processedData.client);
      }
    } else {
      const newClientData = this.initializeNewClient(processedData);
      
      if (this.options?.search) {
        this.mergeSearchData(newClientData);
      }
      
      this.clientObj = newClientData;
    }

    this.clientObj.clientAttributes = ClientEdit.getClientAttributes(this.translateService);
  }

  private initializeNewClient(data: any): any {
    const newData = {
      ...data,
      hideClientID: true,
      client: data.client || new Client(),
      ratesheets: data.ratesheets || [],
      clientaddr: data.clientaddr || new ClientAddr(),
      clientextras: data.clientextras || []
    };

    if (newData.ratesheets.length > 0) {
      newData.client.bbratesheetid = newData.ratesheets[0].bbratesheetid;
    }
    
    newData.client.clienttype = (data.clienttypes && data.clienttypes[0]?.value) || ClientType[0].value;
    newData.clientaddr.add1 = '';
    
    if (newData.clientextras.length === 0) {
      newData.clientextras.push({fieldtype: '', fieldvalue: '', bbclientextraid: 0});
    }
    
    if (newData.client) {
      if (!newData.client.clienttype) {
        newData.client.clienttype = (data.clienttypes && data.clienttypes[0]?.value) || ClientType[0].value;
      }
      newData.client.fullname = this.accountService.getClientFullName(newData.client);
    }
    
    newData.clientExtrasLength = newData.clientextras.length - 1;
    
    return newData;
  }

  private mergeSearchData(data: any): void {
    if (!this.options?.search) return;

    const searchData = this.options.search;
    
    Object.assign(data.client, {
      company: searchData.company || '',
      firstname: searchData.firstname || '',
      surname: searchData.surname || '',
      phoneno: searchData.phoneno || '',
      email: searchData.email || ''
    });

    if (this.clientForm) {
      this.clientForm.markAsDirty();
    }
  }

  handleUpdateRefreshedData() {
    if (!this.returnData || !this.pageData?.onClose) {
      return;
    }

    const updateClientForCompany = (data) => {
      this.companyData(data);
      this.clientForm.markAsDirty();
      this.setFormValues();

      if (this.clientObj.client.bbratesheetid !== 0) {
        this.confirmationService.show({
          title: 'alertReported',
          text: this.translateService.translate('clientAdd', 'clientCompanyChanged'),
          buttons: [{
            text: 'ok',
            callback: () => {
            }
          }]
        });
      }
    };

    const refreshData = () => {
      this.fetchData();
    };

    // Update Payment Function
    const updateTransaction = (transaction, reference) => {
      transaction.reference = reference;
    };

    const addTransaction = (transaction: any) => {
      this.activeID = 5;
      this.calculateBalanceOnAccount();
    };

    // find out from which function we were redirected
    let onClose;
    switch (this.pageData.onClose) {
      case 'updateClientForCompany':
        onClose = updateClientForCompany;
        break;
      case 'refreshData':
        onClose = refreshData;
        break;
      case 'updateTransaction':
        onClose = updateTransaction.bind(this, this.pageData.onCloseParameter);
        break;
      case 'addTransaction':
        onClose = addTransaction;
        break;
    }

    if (onClose) {
      onClose.bind(this)(this.returnData);
    }
  }

  populateClientDetailsFromSearch(data: any): any {
    const updatedData = cloneDeep(data);
    
    updatedData.client.company = this.options?.search?.company || '';
    updatedData.client.firstname = this.options?.search?.firstname || '';
    updatedData.client.surname = this.options?.search?.surname || '';
    updatedData.client.phoneno = this.options?.search?.phoneno || undefined;
    updatedData.client.email = this.options?.search?.email || undefined;
    
    this.clientForm.markAsDirty();
    
    return updatedData;
  }

  populateClientDetailsFromCachedData(data: any): any {
    const updatedData = cloneDeep(data);
    const formValues = this.options.formValues || {};
  
    const clientFields = [
      'clienttype', 'title', 'firstname', 'surname', 'phoneno',
      'bbclientid', 'company', 'email', 'bbratesheetid', 'notes'
    ];
  
    clientFields.forEach(field => {
      if (formValues[field] !== undefined) {
        updatedData.client[field] = formValues[field];
      }
    });
  
    if (formValues.datecreated !== undefined) {
      updatedData.dateCreated = formValues.datecreated;
    }
  
    const addrFields = ['vatno', 'add1', 'add2'];
    addrFields.forEach(field => {
      if (formValues[field] !== undefined) {
        updatedData.clientaddr[field] = formValues[field];
      }
    });
  
    return updatedData;
  }

  calculateBalanceOnAccount() {
    const ZERO_TOLERANCE = 0.005;
    this.clientObj.balanceOnAcc = 0;
    this.clientObj.transactions?.forEach((tran: any) => {
      this.clientObj.balanceOnAcc += tran.amount;
      if (tran.trantype == "CRN" && !this.latestCreditNote) {
        this.latestCreditNote = tran.tranno;
      }
      if (tran.trantype == "INV" && !this.latestInvoice) {
        this.latestInvoice = tran.tranno;
      }
    });
    if (Math.abs(this.clientObj.balanceOnAcc) < ZERO_TOLERANCE) {
      this.clientObj.balanceOnAcc = 0;
    }
  }

  formatDate(date) {
    return this.datelibraryService.getLocalDate(date);
  }

  selectCompany() {
    this.getFormValues();
    this.pageRouteService.navigate(this.getCurrentUrl(),
      {
        ...this.options,
        onClose: 'updateClientForCompany',
      }, ['/clients/company-search'], {clientObj: this.clientObj});
  }

  navigateToBooking(booking) {
    this.pageRouteService.navigate(this.getCurrentUrl(), this.options, ['/booking-summary/' + booking.bookingid], {
      bookingid: booking.bookingid,
    });
  }

  addTransaction() {
    this.pageRouteService.navigate(this.getCurrentUrl(), {
      ...this.options,
      onClose: 'addTransaction',
    }, ['/clients/add-transaction'], {
      clientid: this.clientObj.client.clientid,
    });
  }

  viewTransaction(transaction) {
    if (transaction.trantype === Constants.CREDIT_NOTE) {
      if (transaction.attachedtoinvoice) {
        this.openDocument('CREDITNOTE', transaction.tranno);
      } else {
        this.confirmationService.show({
          title: 'alertReported',
          text: this.translateService.translate('clientAdd', 'creditNoteNotLinked'),
          buttons: [{
            text: 'ok',
            callback: () => {
            }
          }]
        });
      }
    } else if (transaction.trantype === Constants.INVOICE) {
      this.openDocument('INVOICE', transaction.tranno);
    } else if (transaction.trantype === Constants.PAYMENT) {
      this.pageRouteService.navigate(this.getCurrentUrl(), {
        ...this.options,
        onClose: 'updateTransaction',
        onCloseParameter: transaction,
      }, ['/payment'], {
        payment: transaction,
      });
    }
  }

  viewAccount(accountID) {
    this.pageRouteService.navigate(this.getCurrentUrl(), {
      ...this.options,
      onClose: 'refreshData',
    }, ['/account-search/account-detail/' + accountID], {
      accountid: accountID,
    });
  }

  openStatementModal() {
    this.pageRouteService.navigate(this.getCurrentUrl(), {
      ...this.options,
      onClose: 'closeModal',
    }, ['/account-search/create-statement/' + this.clientObj.client.clientid], {
      clientid: this.clientObj.client.clientid,
      email: this.clientObj.client.email,
    });
  }

  rateSheetChanged() {
    if (this.clientObj.client.clientid && (this.clientObj.client.clientid > 0)) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('clientAdd', 'clientRateSheetChanged'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    }
  }

  openDocument(document, data) {
    window.open(environment.apiUrl + '/documents/' + this.credentialService.getLoginKey + '/' + document + '/' + data, '_blank');
  }

  markFormAsDirty() {
    this.clientForm.markAsDirty();
  }

  hasClient() {
    return this.clientObj === undefined ? false : (this.clientObj?.client?.clientid && (this.clientObj?.client.clientid > 0));
  }

  showAccounts() {
    return this.hasClient() &&
      !(this.pageRouteService.isPageInStack('booking-summary') && this.pageRouteService.isPageInStack('account-search/account-detail'));
  }

  private companyData(data) {
    this.clientObj.client = data.client;
    this.clientObj.clientaddr = data.clientaddr;
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }

  validateName() {
    const firstName = this.clientForm.get('firstname').value;
    const surname = this.clientForm.get('surname').value;
    const company = this.clientForm.get('company').value;
    if (firstName.length === 0 && surname.length === 0 && company.length === 0) {
      this.clientForm.markAllAsTouched();
      this.clientForm.markAsDirty();
      this.clientForm.get('firstname').setErrors({valid: false});
      this.clientForm.get('surname').setErrors({valid: false});
      this.clientForm.get('company').setErrors({valid: false});
    } else {
      this.clientForm.get('firstname').setErrors(null);
      this.clientForm.get('surname').setErrors(null);
      this.clientForm.get('company').setErrors(null);
    }

    this.clientForm.updateValueAndValidity();
  }

  /*
  * Opens the invoice/proforma/creditnote html viewer
  * params: template - "invoice" / "proforma" / "creditnote"
  */

  openHTMLViewer(transaction) {
    const template = transaction.trantype === "CRN" ? "creditnote" : "invoice";
    if (transaction.trantype === Constants.CREDIT_NOTE && !transaction.attachedtoinvoice) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('clientAdd', 'creditNoteNotLinked'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    } else {
      this.pageRouteService.navigate('/clients/edit/' + this.route.snapshot.children[0].children[0].params.clientId, {}, ['/clients/transactions/' + template + '/' + transaction.tranno], {
        clientid: this.route.snapshot.children[0].children[0].params.clientId,
        email: this.clientObj.client.email ? this.clientObj.client.email : ''
      });
    }
  }
}
