export class BookingRoom {
  bookingId: number;
  bbRoomId: number;
  bbRtId: number;
  bbRateId: number;
  title: string;
  firstName: string;
  surname: string;
  email: string;
  phoneno: string;
  company: string;
  noAdults: number;
  child1: number;
  child2: number;
  guestId: number;
  bbGuestId: number;
  checkedIn: boolean;
  checkedOut: boolean;
}
