<div class="d-flex flex-column gap-2 m-3 min-w-sm-620" [formGroup]="sourceSearchForm">
  <div class="d-flex flex-column flex-sm-row align-items-center gap-2">
    <cui-input type="text" label="Source Name" placeholder="Search" class="flex-3 w-100" [sizing]="'md'"
      formControlName="sourceName">
    </cui-input>
    <xcui-button (click)="addSource()" type="tonal" class="flex-2 h-48 w-100"> Add Source
    </xcui-button>
  </div>

  <div class="nb-adaptive-table">
    <div class="nb-adaptive-table-header">
      <div class="nb-adaptive-table-cell">Source</div>
    </div>
    <div class="table-body">
      <div class="table-wrapper">
        <div class="nb-adaptive-table-row color-neutral mt-3" (click)="selectSource(source)"
            *ngFor="let source of sources | filter: sourceSearchForm.get('sourceName')?.value; let i = index" [class.nb-table-row-striped]="i % 2 === 1">
          <div class="nb-adaptive-table-cell">
            <div class="inline-label fw-semibold">Source:&nbsp;</div>
            {{ source.sourcename }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
