<div class="modal-header border-bottom" [ngClass]="getClasses('headerClass')">
  <div [ngClass]="getClasses('titleContainerClass', 'd-flex align-items-center pb-2 w-100')">
    <xcui-button *ngIf="onCloseOptions?.showBackButton" type="icon" classes="btn-back" leadingIcon="chevron-back" (click)="close()"></xcui-button>
    <h5 class="modal-title fs-6 fs-sm-18 fw-bold lh-24">{{ title }}</h5>
  </div>
  <xcui-button type="close" classes="btn-close-alt" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></xcui-button>
</div>

<div class="modal-body">
  <ng-template #bodyContent></ng-template>
</div>

<div *ngIf="buttons?.length > 0" class="modal-footer border-top"
  [ngClass]="{'justify-content-between': options?.footerFlexboxAlignment == 'between', 'justify-content-end': options?.footerFlexboxAlignment == undefined}">
  <ng-container *ngFor="let button of buttons">
    <xcui-button *ngIf="!button.hidden" (onClick)="buttonClick(button)" [type]="button.type" [class]="button.wrappingClass" [classes]="button.class" class="flex-0" [disabled]="button.disabled" [iconSize]="button.iconSize" [leadingIcon]="button.leadingIcon"
      label="{{'confirmation' | translate : button.text}}" name="{{'confirmation' | translate : button.text}}"
    >
    </xcui-button>
  </ng-container>
</div>

<img *ngIf="backdropIllustration" class="illustration" [src]="backdropIllustration">
