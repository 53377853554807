<div class="d-flex flex-column m-3 width-735-lg">
  <div class="d-flex flex-column flex-sm-row align-items-sm-center gap-1 mb-2">
    <div class="flex-3">
      <div class="d-flex flex-row">
        <span class="flex-2 fw-semibold">Invoice to:</span>
        <span class="flex-3 fw-normal">{{guestdetails?.clientname}}</span>
      </div>

      <div class="d-flex flex-row">
        <span class="flex-2 fw-semibold">Room:</span>
        <span class="flex-3 fw-normal">{{guestdetails?.roomname}}</span>
      </div>
    </div>
    <xcui-button type="filled" class="me-2 flex-1 h-40" (click)="addExtra()"> Add Extra </xcui-button>
  </div>

  <div class="nb-adaptive-table">
    <div class="nb-adaptive-table-header">
      <div class="nb-adaptive-table-cell">Account ID</div>
      <div class="nb-adaptive-table-cell">Date</div>
      <div class="nb-adaptive-table-cell text-end">Description</div>
      <div class="nb-adaptive-table-cell text-end">Qty x Amount</div>
      <div class="nb-adaptive-table-cell text-end">Total</div>
    </div>
    <div class="nb-adaptive-table-row color-neutral mt-3" *ngFor="let extra of extras; let i = index"
      [class.nb-table-row-striped]="i % 2 === 1"
      (click)="extra.accountstatus !== 'O' ? f_accountClosedWarning() : editExtra(extra)">
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Account ID:&nbsp;</div>
        {{ extra.bbaccountid }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Date:&nbsp;</div>
        {{getLocalDate(extra.extradate)}}
      </div>
      <div class="nb-adaptive-table-cell text-end">
        <div class="inline-label fw-semibold">Description:&nbsp;</div>
        {{ extra.description }}
      </div>
      <div class="nb-adaptive-table-cell text-end">
        <div class="inline-label fw-semibold">Qty x Amount:&nbsp;</div>
        {{formatNumbersForDisplay(extra.qty)}} &times; {{extra.price | currency }}
      </div>
      <div class="nb-adaptive-table-cell text-end">
        <div class="inline-label fw-semibold">Total:&nbsp;</div>
        {{getTotals(extra.price, extra.qty) | currency }}
      </div>
    </div>
  </div>
  <div class="nb-adaptive-table-footer d-flex flex-row justify-content-start justify-content-sm-end align-items-center">
    <span class="fw-semibold m-2">Extras Totals:</span>
    <span class="fw-normal m-2"> {{extrasTotal | currency}}</span>
  </div>
</div>
