import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-booking-id-pills',
  template: `
    <div class="d-flex flex-wrap align-items-center">
  <div class="badge rounded-pill text-primary color-tertiary border border-neutral d-flex align-items-center me-1">
    <span class="fw-semibold me-1">
      NBID:
    </span>
    <span class="fw-normal">
      {{NBId}}
    </span>
  </div>
  <div class="badge rounded-pill text-primary bg-white border border-neutral d-flex align-items-center">
    <span class="fw-semibold me-1">
      Booking ID:
    </span>
    <span class="fw-normal">
      {{bookingId}}
    </span>
  </div>
</div>
  `,
  styles: [`
    .badge {
      font-size: 1rem;
    }

    .badge span {
      vertical-align: middle;
    }

    @media (min-width: 576px) {
      .badge {
        font-size: 0.75rem;
      }
    }
  `],
})
export class BookingIdPillsComponent {

  @Input() NBId!: string;
  @Input() bookingId!: string;

}
