<div class="d-flex gap-1  h-48">
    <div class="d-flex flex-3 flex-md-2 gap-1">
        <div class="flex-1 flex-md-2">
          <x-cui-date-selector 
            [(ngModel)]="startDate"
            [viewMode]="2"
            [dateText]="'dateRange' | translate:( labelMode === 1 ? 'startDate' : 'arrival')"
            [readOnly]="true"
            [iconColor]="'color-mono-primary-text'">
          </x-cui-date-selector>
        </div>

        <div class="inputField__label align-self-center d-none d-sm-block" *ngIf="!showLabel">
            {{'dateRange' | translate: 'toDate'}}
        </div>

        <div class="flex-1">
          <x-cui-date-selector 
            [(ngModel)]="endDate"
            [viewMode]="2"
            [dateText]="'dateRange' | translate:( labelMode === 1 ? 'endDate' : 'departure')"
            [readOnly]="true"
            [iconColor]="'color-mono-primary-text'">
          </x-cui-date-selector>
        </div>
    </div>

    <div class="flex-1">
        <cui-input
          type="number"
          min="1"
          max="365"
          [label]="'dateRange' | translate:(labelMode === 1 ? 'days' : 'nights')"
          [(ngModel)]="duration"
          sizing="md"
          (input)="onInputChange($event)">
        </cui-input>
    </div>
</div>
