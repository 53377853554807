import {FormArray, FormGroup} from "@angular/forms";

export const loadNavigatorScript = (url: string) => {
  const scriptEl = window.document.createElement('script');
  scriptEl.src = url;
  window.document.body.appendChild(scriptEl);
}

export function formatAmountAsDecimalNumber(amount: string) {
  // Replace commas and dots with an empty string from the end of the string
  amount = amount.replace(/[,.]+$/, '');
  // Replace commas with dots before the last 2 digits
  amount = amount.replace(/,(?=\d{2}$)/, '.');
  // Remove commas and non-numeric characters except negative '-'
  amount = amount.replace(/[^-0-9.]/g, '');
  // Ensure there is at most one dot for decimal places
  const dotCount = amount.split('.').length - 1;
  amount = dotCount <= 1 ? amount : amount.replace(/\.(?=.*\.)/g, '');
  // If there is no dot at the end of the line append ".00"
  if (amount.indexOf('.') === -1) {
    amount += '.00';
  }
  // Format to two decimal places and return the amount
  return parseFloat(amount).toFixed(2);
}


export const buildQueryString = (params: object | undefined) => {
  let queryString = "";
  if (params) {
    for (const para in params) {
      const key = para;
      // @ts-ignore
      const value = params[para];

      if (queryString && value) {
        queryString += `&${key}=${value}`;
      } else if (value) {
        queryString += `?${key}=${value}`;
      }
    }
  }

  return queryString;
}

export const flattenObject = (array: [], obj: any, char: string, parentKey: string) => {
  if (typeof obj === "object")
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        flattenObject(array, obj[key], "-", parentKey + char + key)
      } else {
        // @ts-ignore
        array[parentKey + char + key] = obj[key];
      }
    }
}

export const flattenObjectToArrayJoin = (obj: any, char: string) => {
  const flatObject: [] = [];
  for (const key in obj) {
    if (typeof obj[key] === "object") {
      flattenObject(flatObject, obj[key], "-", key)
    } else {
      // @ts-ignore
      flatObject[key] = obj[key];
    }
  }
  return flatObject;
}

export const getScopeFromUrl = (url: string) => {
  const scopeRoute = url.split('/').filter((item) => item !== '').join('-');
  return scopeRoute;
};

export const isOnRootCalendarUrl = () => {
  const url = new URL(window.location.href);
  return url.pathname === "/" && url.search === "";
}

export const markAllAsTouched = (formGroup: FormGroup | FormArray) => {
  Object.values(formGroup.controls).forEach(control => {
    control.markAsTouched();

    if (control instanceof FormGroup || control instanceof FormArray) {
      markAllAsTouched(control);
    }
  });
}

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}
