import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private router: Router) {
    this.initialize();
  }

  private initialize() {
    try {
      this.loadGoogleAnalytics();

      this.router.events
        .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          const url = event.urlAfterRedirects;

          if (!url.startsWith('/loginkey')) {

            const pageTitle = this.getTitle(this.router.routerState.snapshot.root);
            const pageLocation = window.location.href;
            const pagePath = event.urlAfterRedirects;

            this.trackPageView(pageTitle, pageLocation, pagePath);
          }
        });
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }

  private loadGoogleAnalytics() {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${environment.googleAnalyticsId}', {'send_page_view': false});
    `;
    document.head.appendChild(inlineScript);
  }

  private getTitle(root: any): string {
    let currentRoute = root;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    return currentRoute.data['title'] || 'Default Title'; // Provide a default
  }

  private trackPageView(pageTitle, pageLocation, pagePath) {
    (window as any).gtag('event', 'page_view', {
      'page_title': pageTitle, // Optional: Set the page title
      'page_location': pageLocation, // Optional: Set the full URL
      'page_path': pagePath // Optional: Set the page path
    });
  }
}
