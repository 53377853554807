import {ChangeDetectorRef, Component, HostListener, Input, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NightsbridgeIconsId} from 'nightsbridge-icons/nightsbridge-icons';
import {PageRouteService} from 'src/app/service/page-route.service';
import {SimpleModalCloseOptions, SimpleModalContent, SimpleModalStyles} from './simple-modal.contract';
import {ButtonType, IconSize} from '@cui/index';

@Component({
  selector: 'app-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss']
})
export class SimpleModalComponent implements SimpleModalContent {
  title: string;
  customStyles: SimpleModalStyles = {};
  buttons: {
    id?: any;
    type?: ButtonType;
    text: string;
    class: string;
    hidden?: boolean;
    disabled?: boolean;
    leadingIcon?: NightsbridgeIconsId;
    iconSize?: IconSize;
    wrappingClass?: string
    callback: () => void;
  }[];
  onCloseOptions = {
    onModalClose: 'close'
  } as SimpleModalCloseOptions;
  backdropIllustration?: string;

  @Input() options: any;
  @ViewChild('bodyContent', {read: ViewContainerRef}) bodySlot: ViewContainerRef;
  propertyTest: boolean = false;

  private bodyComponent: Type<any>;
  public inputData: any;

  constructor(
    public activeModal: NgbActiveModal,
    private pageRouteService: PageRouteService,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.injectContent();
  }

  protected getClasses(area: keyof SimpleModalStyles, defaultClasses: string = ''): string {
    return `${defaultClasses} ${this.customStyles[area] || ''}`.trim();
  }

  prepareContent(bodyComponent?: Type<any>, inputData?: any) {
    this.bodyComponent = bodyComponent;
    this.inputData = inputData;
    this.options = inputData.options;
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKeyPress(event: KeyboardEvent) {
    const button = this.options?.buttons?.find(b => b.triggerOnEnter);
    if (button && event.key === "Enter") {
      event.preventDefault();
      this.buttonClick(button);
    }
  }

  private injectContent() {
    if (this.bodyComponent) {
      const bodyRef = this.bodySlot.createComponent(this.bodyComponent);

      if (bodyRef?.instance?.title || (this.inputData?.options && this.inputData?.options?.title)) {
        this.title = bodyRef.instance.title || this.inputData?.options?.title;
      };

      if (bodyRef?.instance?.customStyles || (this.inputData?.options && this.inputData?.options?.customStyles)) {
        this.customStyles = bodyRef.instance.customStyles || this.inputData?.options?.customStyles;
      }

      if (bodyRef?.instance?.backdropIllustration || (this.inputData?.options && this.inputData?.options?.backdropIllustration)) {
        this.backdropIllustration = this.inputData?.options?.backdropIllustration || bodyRef.instance.backdropIllustration;
      };

      this.buttons = bodyRef.instance.buttons || this.inputData?.options?.buttons;
      this.onCloseOptions = bodyRef.instance.onCloseOptions || this.inputData?.options?.onCloseOptions;
      if (this.options?.footerFlexboxAlignment || bodyRef?.instance?.footerFlexboxAlignment) {
        this.options.footerFlexboxAlignment = this.options?.footerFlexboxAlignment ? this.options?.footerFlexboxAlignment : bodyRef?.instance?.footerFlexboxAlignment
      };
      Object.assign(bodyRef.instance, this.inputData);

      if (bodyRef.instance.buttonStateChange) {
        bodyRef.instance.buttonStateChange.subscribe(({id, ...changes}) => {
          if (id) {
            this.buttons = this.buttons.map(button => {
              if (button.id === id) {
                return {...button, ...changes};
              }
              return button;
            });
          } else {
            this.buttons = this.buttons.map(button => ({...button, ...changes}));
          }
        });
      }

      if (bodyRef.instance.titleStateChange) {
        bodyRef.instance.titleStateChange.subscribe((title) => {
          this.title = title;
        });
      }
      
      if (bodyRef.instance.closeOptionsChange) {
        bodyRef.instance.closeOptionsChange.subscribe((onCloseOptions) => {
          this.onCloseOptions = onCloseOptions;
        });
      }
    }
    this.cdr.detectChanges();
  }

  close() {
    const actions = {
      'back': () => this.pageRouteService.back(this.onCloseOptions.returnData),
      'backAndClose': () => {
        this.activeModal.close();
        this.pageRouteService.back(this.onCloseOptions.returnData);
      },
      'refresh': () => window.location.reload(),
      'stackAwareClose': () => {
        if (this.pageRouteService.isPageInStack(this.onCloseOptions?.pagesInStackCondition)) {
          this.pageRouteService.close();
        }
        this.pageRouteService.back(this.onCloseOptions.returnData);
      }
    };
  
    const closeAction = this.onCloseOptions?.onModalClose;
    const action = actions[closeAction];
    
    return action ? action() : this.activeModal.close();
  }

  buttonClick(button) {
    button.callback();

    if (!this.onCloseOptions?.preventAutoClose) {
      this.activeModal.close();
    }
  }
}
