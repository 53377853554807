<div class="account-info-container d-flex flex-column">
  <div class="d-flex flex-column gap-1 mt-3">
    <cui-button mode="secondary" type="tonal" classes="btn-secondary fw-semi-bold p-2 h-40 m-0" (onClick)="viewAccount()"> View Account </cui-button>
    <cui-button mode="secondary" type="tonal" classes="btn-secondary fw-semi-bold p-2 h-40 m-0" (onClick)="viewRates()"> View Rates </cui-button>
    <cui-button mode="secondary" type="tonal" classes="btn-secondary fw-semi-bold p-2 h-40 m-0" (onClick)="checkout()"> Check Out </cui-button>
    <ng-container class="report-buttons" *ngIf="reportInvalidCCBookingStatus || reportNoShowStatus">
      <div class="d-flex flex-row gap-1">
        <cui-button  *ngIf="reportNoShowStatus === ReportBookingStatus.AVAILABLE" (click)="invalidCC()" mode="secondary" type="tonal" class="flex-grow-1 flex-shrink-0 flex-basis-0" classes="fw-semi-bold p-2 h-40 color-info-light color-warning-text-light m-0"> {{'bookingSummary'|translate: (invalidCCReported ? 'badCCReported': 'invalidCC')}} </cui-button>
        <ng-container *ngIf="reportNoShowStatus === ReportBookingStatus.REPORTED"> <ng-container *ngTemplateOutlet="ccReportedButton"></ng-container> </ng-container>
        <cui-button *ngIf="reportNoShowStatus === ReportBookingStatus.AVAILABLE" (click)="noShow()"  mode="light" type="tonal" class="flex-grow-1 flex-shrink-0 flex-basis-0" classes="fw-semi-bold p-2 h-40 color-info-light color-warning-text-light m-0"> {{'bookingSummary'|translate: (noShowReported ? 'noShowReported': 'noShow')}} </cui-button>
        <ng-container *ngIf="reportNoShowStatus === ReportBookingStatus.REPORTED"> <ng-container *ngTemplateOutlet="noShowReportedButton"></ng-container> </ng-container>
      </div>
    </ng-container>
  </div>
  <hr>
  <div class="fw-semi-bold mb-2"> Totals </div>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-content-between">
      <div> Accommodation: </div>
      <div> {{formatCurrency(bookingSummaryObject.bookingaccount.accomodationtotal)}} </div>
    </div>
    <div class="d-flex flex-row justify-content-between">
      <div> Extras: </div>
      <div> {{formatCurrency(bookingSummaryObject.bookingaccount.extratotal)}} </div>
    </div>
    <div class="d-flex flex-row justify-content-between">
      <div> Payments: </div>
      <div> {{formatCurrency(bookingSummaryObject.bookingaccount.paymenttotal)}} </div>
    </div>
    <div class="d-flex flex-sm-row justify-content-between">
      <div class="fw-semi-bold"> Outstanding: </div>
      <div> {{formatCurrency(bookingSummaryObject.bookingaccount.outstandingbalance)}} </div>
    </div>
  </div>
  <hr>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-content-between">
      <div class="fw-semi-bold lh-30"> Deposit: </div>
      <div class="d-flex gap-1">
        <cui-button (click)="calculateDepositClicked.emit()" classes="btn__regular-icon--active-blue btn__icon--round-desktop button-ic_person m-0 btn-desktop p-0 h-32 w-32" mode="light" type="icon-filled" sizing="sm" leadingIcon="refresh" ngbTooltip="Calculate Deposit" tooltipClass="tooltip__container"></cui-button>
        <div class="lh-30"> {{formatCurrency(bookingSummaryObject.bookingaccount.displaydeposittotal)}} </div>
      </div>
    </div>
    <div *ngIf="(tapNpayFeatureStatus$ | async) as tapNpayFeatureStatus">
        <hr>
        <div class="d-flex">
            <div class="flex-1 m-1">
                <div class="buttons">
                    <ng-container>
                        <app-tap-n-pay
                                [classes]="'p-1 p-sm-2 h-40 ml-0'"
                                [paymentTapFeatureStatus]="tapNpayFeatureStatus"
                                [setupPageUrl]="setupPageUrl"
                                [details]="{
                                  bbId: bookingSummaryObject.booking.bbid,
                                  amount: 0,
                                  bookingId: bookingSummaryObject.booking.bookingid,
                                  mode: 'payment',
                                  refundPaymentId: '',
                                  currencyCode: getCurrency()
                                }"
                                [bookingDetails]="{
                                  amountDue: 0,
                                  accommodationTotal: bookingSummaryObject.bookingaccount.accomodationtotal,
                                  extraTotal: bookingSummaryObject.bookingaccount.extratotal,
                                  paymentsTotal: bookingSummaryObject.bookingaccount.payments,
                                  clientFullName: bookingSummaryObject.madebyclient?.fullname,
                                  fromDate: bookingSummaryObject.booking.fromdate,
                                  toDate: bookingSummaryObject.booking.todate
                                }"
                        ></app-tap-n-pay>
                    </ng-container>
                </div>
            </div>
            <div class="flex-1 totals-column m-1 p-left-12">
                <!-- PLACEHOLDER DIV: DO NOT DELETE -->
            </div>
        </div>
    </div>
    <hr>
  </div>
  <div class="status-section d-flex justify-content-center justify-content-sm-start mb-1">
    <div class="w-100">
      <cui-button (click)="viewStatusAndHistory()" mode="secondary" type="tonal" classes="btn-secondary p-2 h-40 m-0 fs-16">
        <div class="d-flex gap-2">
          <div class="fw-semi-bold text-nowrap">
            <span class="d-none d-sm-inline"> View </span> Payment Status & Booking History
          </div>
          <div class="chevron-arrow d-none fw-semi-bold p-0 d-sm-block lh-21 fs-20"> › </div>
        </div>
      </cui-button>
    </div>
  </div>
  <div class="d-flex m-2 flex-column flex-sm-row justify-content-sm-between">
    <div class="mt-1 fs-16 fs-sm-14">
      <span class="fw-bold">{{"bookingSummary" | translate:'entered'}}</span>
      {{getLocalDateTime(bookingSummaryObject.booking?.bookingdate)}}
    </div>
    <div class="mt-1 fs-16 fs-sm-14">
      <span class="fw-bold">{{"bookingSummary" | translate:'type'}}</span>
      {{bookingSummaryObject.bookingtype}}
    </div>
  </div>
</div>

<ng-template #ccReportedButton>
    <div class="position-relative flex-grow-1 flex-shrink-0 flex-basis-0">
     <cui-button [disabled]="true" type="tonal" classes="fw-semi-bold p-3 color-info-light color-warning-text-light text-wrap lh-16 m-0"> {{'bookingSummary'|translate: 'badCCReported'}} </cui-button>
      <div class="btn__badge_responsive_warning position-absolute alert-badge-absolute-right-top">
        <sup class="btn__badge_responsive_warning--icon">
          <svg class="ic ic_bell">
            <use href="assets/images/icons/sprite.svg#ic_bell"/>
          </svg>
        </sup>
      </div>
    </div>
</ng-template>

<ng-template #noShowReportedButton>
    <div class="position-relative flex-grow-1 flex-shrink-0 flex-basis-0">
      <cui-button [disabled]="true" mode="light" type="tonal" classes="fw-semi-bold p-3 color-info-light color-warning-text-light lh-16 m-0"> {{'bookingSummary'|translate: 'noShowReported'}} </cui-button>
      <div class="btn__badge_responsive_warning position-absolute alert-badge-absolute-right-top">
        <sup class="btn__badge_responsive_warning--icon">
          <svg class="ic ic_bell">
            <use href="assets/images/icons/sprite.svg#ic_bell"/>
          </svg>
        </sup>
      </div>
    </div>
</ng-template>
