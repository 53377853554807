<app-edit-client-summary [clientForm]="clientForm" (selectCompany)="selectCompany()" [hasClient]="hasClient()"
  [clientObj]="clientObj" [clienttypes]="clienttypes"></app-edit-client-summary>
<div class="position-relative h-100">
  <app-simple-tabs>
    <ng-template appTab title="VAT/Address">
      <app-edit-client-vat-address [clientForm]="clientForm">
      </app-edit-client-vat-address>
    </ng-template>
    <ng-template appTab title="Extra Info">
      <app-edit-client-extra-info [clientForm]="clientForm" [clientAttributes]="clientAttributes"
        (addClientExtra)="addClientExtra()" (removeClientExtra)="removeClientExtra($event)">
      </app-edit-client-extra-info>
    </ng-template>
    <ng-template appTab title="Additional">
      <app-edit-client-additional [clientForm]="clientForm" [clientObj]="clientObj"
        (rateSheetChanged)="rateSheetChanged()">
      </app-edit-client-additional>
    </ng-template>
    <ng-template appTab title="Accounts" *ngIf="showAccounts()">
      <app-edit-client-accounts [clientObj]="clientObj" (viewAccount)="viewAccount($event)">
      </app-edit-client-accounts>
    </ng-template>
    <ng-template appTab title="Transactions" *ngIf="hasClient()">
      <app-edit-client-transactions [hasClient]="hasClient()" [clientObj]="clientObj"
        [pdfViewerActive]="pdfViewerActive" [latestInvoice]="latestInvoice" [latestCreditNote]="latestCreditNote"
        [invoiceTemplatePreference]="invoiceTemplatePreference" [constants]="constants"
        (viewTransaction)="viewTransaction($event)" (addTransaction)="addTransaction()"
        (openHTMLViewer)="openHTMLViewer($event)">
      </app-edit-client-transactions>
    </ng-template>
    <ng-template appTab title="History" *ngIf="hasClient()">
      <app-edit-client-history [hasClient]="hasClient()" [clientObj]="clientObj"
        (navigateToBooking)="navigateToBooking($event)">
      </app-edit-client-history>
    </ng-template>
  </app-simple-tabs>
</div>

<div class="modal-footer border-top"
  [ngClass]="{'justify-content-between': hasClient(), 'justify-content-end': !hasClient()}">
  <ng-container>
    <div class="d-flex flex-row gap-2" *ngIf="hasClient()">
      <xcui-button [classes]="editClientButtons[0].class" class="flex-0" label="{{'confirmation' | translate : editClientButtons[0].text}}" name="{{'confirmation' | translate : editClientButtons[0].text}}" (onClick)="buttonClick(editClientButtons[0].id)">
      </xcui-button>

      <div class="d-flex flex-column">
        <span class="fw-normal fs-14">
          Balance on Account
        </span>
        <span class="fw-semibold">
          {{(clientObj?.balanceOnAcc ?? 0) | currency}}
        </span>
      </div>
    </div>

    <xcui-button [classes]="editClientButtons[1].class" class="flex-0" label="{{'confirmation' | translate : editClientButtons[1].text}}" name="{{'confirmation' | translate : editClientButtons[1].text}}" (onClick)="buttonClick(editClientButtons[1].id)">
    </xcui-button>
  </ng-container>
</div>
