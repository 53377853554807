<div class="d-flex flex-column flex-md-row my-3 mx-3 gap-3 align-items-md-center" [formGroup]="bookingSummaryForm">
  <x-cui-date-selector [viewMode]="2" dateText="Date" formControlName="statusdate" class="flex-2">
  </x-cui-date-selector>

  <div class="h-48">
    <timepicker formControlName="statustime" [showSpinners]="false" [showMeridian]="false" class="h-48"
      ngbTooltip="{{ 'bookingSummary'| translate: 'hourMin'}}" tooltipClass="tooltip__container"></timepicker>
  </div>
</div>

<!--<div class="d-flex flex-row h-48 align-items-center flex-1 cursor-pointer" (click)="openUntilModal.emit()" *ngIf="bookingInfoForm.get('status').value == 'P' || bookingInfoForm.get('status').value == 'R'">
    <div class="ic-time-picker">
      <svg class="ic color-secondary-text">
        <use href="assets/images/sprite.svg#time-picker" />
      </svg>
    </div>
    &nbsp;
    <div class="d-flex flex-column">
      <span class="fw-semibold">Until</span>
      <div class="d-flex flex-row align-items-center">
        <span class="fw-normal">{{ bookingInfoForm.get('statusdate').value | date: 'yyyy/MM/dd' }}</span>•
        <span class="fw-normal">{{ bookingInfoForm.get('statustime').value | date: 'HH:mm' }}</span>
      </div>
    </div>
  </div>
Note: if we are opening via modal, re-add this part to booking info summary
-->