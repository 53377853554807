export const environment = {
  production: true,
  apiUrl: 'https://test-bridgeit.nightsbridge.com',
  setupEndPoint: 'https://test-nb.nightsbridge.com/bridge',
  nbDashboard: 'https://test-nb.nightsbridge.com/dashboard',
  loginUrl: 'https://test-login.nightsbridge.com/index.html',
  websocketUrl: 'wss://test-bridgeit.nightsbridge.com/web/websocket',
  navigatorUrl: 'https://cdn.test.nightsbridge.com/bridge-navigator/bridge-navigator-ui-element.js',
  featureFlags: {
    "placeholder": true
  },
  googleAnalyticsId: 'G-BG0P7LQ227'
};
