import {Injectable} from '@angular/core';
import {ModalContainerService} from './modal-container.service';
import {CredentialService} from './credential.service';
import {PanelContainerService} from './panel-container.service';
import {GridBreakpoints} from '../app.settings';
import {Meta} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisplayManagerService {
  private _hideCalendar = new BehaviorSubject<boolean>(false);
  public hideCalendar$ = this._hideCalendar.asObservable();

  constructor(
    private modalContainerService: ModalContainerService,
    private panelContainerService: PanelContainerService,
    private credentialService: CredentialService,
    private metaService: Meta
  ) { }

  openComponent(componentUrl: any, params: any, state: any) {
    if (this.isAltComponentDisplayActive(componentUrl)) {

      this.updateViewport(this.isMobile());

      if (this.isMobile()) {
        // Open as a fullscreen overlay
        this.setCalendarVisibility(true);
        this.panelContainerService.openPanel(componentUrl, params, state, {isFullScreen: true});
      } else {
        // Open in sidebar
        this.updateViewport();
        this.setCalendarVisibility(false);
        this.panelContainerService.openPanel(componentUrl, params, state, {position: 'right', isFullScreen: false});
      }
    } else {
      // Use existing modal service
      this.updateViewport();
      this.setCalendarVisibility(false);
      this.modalContainerService.openModal(componentUrl, params, state);
    }
  }

  setCalendarVisibility(isHidden: boolean) {
    this._hideCalendar.next(isHidden);
  }

  public isAltComponentDisplayActive(componentUrl: any) {
    // This is a multi stage flag, first it checks if the component has a alternate display then it checks if the user has the permission to view it.

    const componentWithAltDisplay = ['booking-summary', 'clients', 'account-search', 'payment']; // update this list with the components that have alternate display
    const pathsToExclude = ['checkout-payment', 'guest-communications','retire','history'];

    // Check if the component has an alternate display and is not in the exclusion list
    const componentHasAltDisplay = componentWithAltDisplay.includes(componentUrl[0].path);
    const isExcluded = componentUrl.some(segment => pathsToExclude.includes(segment.path));

    const user = this.credentialService.getCurrentUser.value;
    const userHasPermission = user.altComponentDisplayActive;

    return componentHasAltDisplay && userHasPermission && !isExcluded;
  }

  public isMobile(): boolean {
    return window.outerWidth < GridBreakpoints.sm;
  }

  public updateViewport(isMobile = false) {
    const width = isMobile ? 'device-width' : '1300';
    this.metaService.updateTag({name: 'viewport', content: `width=${width}, initial-scale=1`});
   }
}
