<div class="modal-header border-bottom">
  <div class="d-flex align-items-center pb-2">
    <h5 class="modal-title fs-6 fs-sm-18 fw-bold lh-24">{{ options.title }}</h5>
  </div>
  <xcui-button type="close" classes="btn-close-alt" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></xcui-button>
</div>

<div class="modal-body px-3">
  <div class="fs-14 mb-2">{{getTemplateMessage()}}</div>
  <cui-input type="text" name="email" class="w-100" label="Email" [required]="true" [sizing]="'md'"
    placeholder="Enter email" [(ngModel)]="email"></cui-input>
  <div *ngIf="emailError" class="text-danger fs-12 mt-1">{{ emailError }}</div>
  <div class="text__grey-light mb-3 mt-1 fs-12 fw-normal text-black-50">
    {{'accountSummary' | translate: 'additionalEmails'}}
  </div>
</div>


<div class="modal-footer border-top d-flex p-2">
  <cui-input type="checkbox" [label]="'accountSummary' | translate: 'bccMe'"
    class="me-2 d-flex justify-content-start justify-content-sm-center bcc-option"
    classes="fs-14 d-flex align-items-end fw-normal" [(ngModel)]="bcc">
  </cui-input>

  <xcui-button mode="success" (click)="buttonClick(options?.buttons[0])" classes="btn-success min-w-120">
    {{'confirmation' | translate : options?.buttons[0].text}}
  </xcui-button>
</div>
