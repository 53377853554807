import {APP_INITIALIZER, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import {HttpClient} from '@angular/common/http';

import { ModalContainerService } from 'src/app/service/modal-container.service';
import {TranslateService} from 'src/app/service/translate.service';
import {TranslatePipe} from 'src/app/service/translatepipe';
import {ReportService} from 'src/app/service/report.service';
import {CredentialService} from 'src/app/service/credential.service';
import {OrderByPipe} from 'src/app/service/order-by.pipe';
import {FilterPipe} from 'src/app/service/filter.pipe';
import {AccountService} from './account.service';
// import {ClientCardService} from './client-card.service';
import { CurrencyPipe } from './currency.pipe';
// import { DateRangeService } from './date-range.service';
import {HttpInterceptorService} from "./http-interceptor.service";
import {PaymentService} from "./payment.service";
import {ConfirmationService} from "./confirmation.service";
import {GuestCommunicationsService} from "./guest-communications.service";
import {LoadingService} from "./loading.service";
import {OccupancyService} from "./occupancy.service";
import {PreferencesService} from "./preferences.service";
import {SourcesService} from "./sources.service";
import {SourceReplaceService} from "./source-replace.service";
import {DoubleBookingsService} from "./double-bookings.service";
import {CancellationService} from "./cancellation.service";
import { InvoicedNoPipe } from './invoicedno.pipe';
import { SafePipe } from './safe.pipe';
import {MediaService} from "./media.service";
import {PanelContainerService} from './panel-container.service';
import {GoogleAnalyticsService} from "./google-analytics.service";

@NgModule({
  declarations: [
    TranslatePipe,
    OrderByPipe,
    FilterPipe,
    CurrencyPipe,
    InvoicedNoPipe,
    SafePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TranslatePipe,
    OrderByPipe,
    FilterPipe,
    CurrencyPipe,
    InvoicedNoPipe,
    SafePipe
  ],
  providers: [
    {
      provide: 'ModalContainerService',
      useClass: ModalContainerService,
    },
    {
      provide: 'PanelContainerService',
      useClass: PanelContainerService,
    },
    TranslateService,
    TranslateService, {
      provide: APP_INITIALIZER,
      useFactory: translateServiceProvider,
      multi: true,
      deps: [TranslateService, HttpClient]
    },
    ReportService, {
      provide: APP_INITIALIZER,
      useFactory: reportServiceProvider,
      multi: true,
      deps: [ReportService, HttpClient]
    },
    CredentialService, {
      provide: APP_INITIALIZER,
      useFactory: authServiceProvider,
      multi: true,
      deps: [CredentialService, HttpClient]
    },
    PaymentService,
    AccountService,
    // ClientCardService,
    // DateRangeService,
    HttpInterceptorService,
    MediaService,
    GoogleAnalyticsService
  ],
})
export class ServiceModule { }

export function translateServiceProvider(provider: TranslateService): any {
  return () => provider.bootstrap();
}

export function reportServiceProvider(provider: ReportService): any {
  return () => provider.bootstrap();
}

export function authServiceProvider(provider: CredentialService): any {
  return () => provider.bootstrap();
}
