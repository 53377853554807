import {Component, Input, OnInit} from '@angular/core';
import {SimpleModalCloseOptions, SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';
import {AccountService} from 'src/app/service/account.service';
import {Account} from 'src/app/service/models/Account';
import {Client} from 'src/app/service/models/Client';
import {Constants} from 'src/app/service/models/enum/constants.enum';
import {Items} from 'src/app/service/models/Items';
import {Payment} from 'src/app/service/models/Payment';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {PaymentService} from 'src/app/service/payment.service';
import {ToastService} from '@nightsbridge/common-ui/toasts';
import {LoadingService} from 'src/app/service/loading.service';
import {CalendarResponse} from '@core/models';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {CredentialService} from 'src/app/service/credential.service';
import {TranslateService} from 'src/app/service/translate.service';

@Component({
  selector: 'app-account-item-actions',
  templateUrl: './account-item-actions.component.html',
  styleUrls: ['./account-item-actions.component.scss']
})
export class AccountItemActionsComponent implements OnInit, SimpleModalContent {
  @Input() title = 'What would you like to do with this item?';
  @Input() options;

  item: Items;
  client: Client;
  account: Account;
  clientAccounts: Account[] = [];

  payment = undefined;

  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'backAndClose',
  };

  constructor(
    protected pageRouteService: PageRouteService,
    private pageRouteDataService: PageRouteDataService,
    private accountService: AccountService,
    private paymentService: PaymentService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private authService: CredentialService,
    public translateService: TranslateService) { }

  ngOnInit(): void {
    const returnData = this.pageRouteDataService.getReturnData();

    if (!returnData && !this.options?.item) {
      this.pageRouteService.back();
    }

    this.payment = new Payment();
    this.item = this.options?.item || returnData?.item;
    this.client = this.options?.client || returnData?.client;
    this.account = this.options?.account || returnData?.account;

    this.getPayment();
    this.getClientAccounts();
  }

  getPayment() {
    this.loadingService.showLoadingScreen();
    const data = {
      paymentid: this.item.itemid
    };

    this.paymentService.getPayment(data).subscribe((response: any) => {
      this.payment = response.data.payment;
      this.payment.paymenttype = parseInt(response.data.payment.paymenttype);
      this.loadingService.hideLoadingScreen();
    });
  }

  getAccount(itemtype, itemid, accountid) {
    this.accountService.clientAccount(itemtype, itemid, accountid).subscribe((response) => {
      this.client = response.data.client;
    });
  }

  getClientAccounts() {
    this.accountService.clientAccounts(Constants.ACCOUNT_OPEN, this.client.clientid).subscribe(response => {
      this.clientAccounts = response.data.accounts;
    });
  }

  editPaymentReference() {
    this.pageRouteService.navigate(`account-search/account-detail/${this.account.accountid}`, {
      ...{
        client: this.client,
        account: this.account,
      },
      onClose: 'updateTransaction',
      onCloseParameter: this.item,
    }, ['/payment'], {
      client: this.client,
      account: this.account,
      item: this.item,
      payment: this.item.itemid,
    });
  }

  unallocateItem() {
    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('accountSummary', 'confirmPaymentUnallocate'),
      buttons: [{
        text: 'no',
        class: 'btn__warning',
        wrappingClass: 'flex-1',
        callback: () => {
        }
      }, {
        text: 'yes',
        class: 'btn__green',
        wrappingClass: 'flex-1',
        callback: () => {
          this.loadingService.showLoadingScreen();
          this.payment.accountid = 0;

          this.paymentService.editPayment(this.payment.paymentid, this.payment.reference, this.payment.paymenttype, this.payment.accountid).subscribe((response: CalendarResponse) => {
            if (response.success) {
              this.pageRouteService.back();
              this.toastService.show('Success', 'Payment moved to unallocated account', {classname: 'toast-success', delay: 4000});
            } else {
              this.toastService.show('Error', 'Could not unallocate account', {classname: 'toast-error', delay: 4000});
              this.loadingService.hideLoadingScreen();
            }
          });
        }
      }]
    });

  }

  moveItem() {
    this.pageRouteService.navigate('payment/payment-actions', {
      ...{
        client: this.client,
        account: this.account,
      },
      onClose: 'updateTransaction',
      onCloseParameter: this.item,
    }, ['/payment/move-payment'], {
      client: this.client,
      account: this.account,
      clientAccounts: this.clientAccounts,
      item: this.item,
      payment: this.payment,
    });
  }

  private get isAltComponentDisplayActive() {
    const user = this.authService.getCurrentUser.value;
    return user.altComponentDisplayActive;
  }
}
