<ng-container *ngIf="(bookingSummary$ | async) as bookingSummary">
    <form [formGroup]="bookingInfoForm" class="p-2">

        <app-booking-id-pills class="d-block d-md-none" [NBId]="bookingSummary.booking.bookingid"
            [bookingId]="bookingSummary.booking.bbbookingid"> </app-booking-id-pills>

        <div class="d-flex mt-3 mb-2">
            <app-booking-date-range [range]="dateRange" [labelMode]="2" (onChange)="dateRangeChanged.emit($event)" class="w-100"></app-booking-date-range>
        </div>

        <div class="d-flex justify-content-between align-items-start mt-3 mb-2 fs-14">
            <div class="flex-2 fw-semi-bold">
                {{ (bookingSummary.guests && (bookingSummary.selectedguestIndex !== undefined) &&
                bookingSummary.guests[bookingSummary.selectedguestIndex]) ?
                bookingSummary.guests[bookingSummary.selectedguestIndex].roomname : ''}}
            </div>

            <div class="flex-1 d-flex align-items-center justify-content-end" *ngIf="bookingSummary.guests?.length > 1">
                <div class="subsection-heading me-2">
                    <span>Room</span>
                    {{bookingSummary.selectedguestIndex + 1}}/{{bookingSummary.guests?.length}}
                </div>

                <div ngbDropdown class="d-inline-block" placement="bottom-right">
                    <div class="icon" ngbDropdownToggle [class.rotate]="isIconRotated" (click)="toggleIconRotation()">
                    </div>
                    <div ngbDropdownMenu class="roomDropdown pt-0">
                        <div class="p-1">
                            <cui-input type="text" [placeholder]="'bookingSummary' | translate : 'search'"
                                [(ngModel)]="roomFilter" [ngModelOptions]="{standalone: true}"></cui-input>
                        </div>
                        <button ngbDropdownItem
                            *ngFor="let guest of bookingSummary.guests | filter : roomFilter : 'roomname'"
                            (click)="changeRoomView.emit(guest)" (click)="toggleIconRotation()">
                            {{guest?.roomname}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex my-3 my-md-2 gap-2 flex-wrap">
            <cui-button classes="w-100 rounded btn__roomoptions" class="h-40 flex-1 flex-basis-48 flex-basis-sm-0" (click)="addRoom.emit()"> Add </cui-button>
            <cui-button classes="w-100 rounded btn__roomoptions" class="h-40 flex-1 flex-basis-48 flex-basis-sm-0" (click)="changeRoom.emit()"  *ngIf="!bookingSummary.changeRoomDisabled"> Change </cui-button>
            <cui-button classes="w-100 rounded btn__roomoptions" class="h-40 flex-1 flex-basis-48 flex-basis-sm-0" (click)="splitRoom.emit()"  *ngIf="bookingSummary.guests?.length > 1"> Split </cui-button>
            <cui-button classes="w-100 rounded btn__roomoptions" class="h-40 flex-1 flex-basis-48 flex-basis-sm-0" (click)="cancelRoom.emit()" *ngIf="bookingSummary.guests?.length > 1"> Cancel </cui-button>
        </div>

        <div class="d-flex mt-1 mb-2">
            <cui-input type="select" class="w-100" dropdownOptionsLabel="description"
                [dropdownOptions]="bookingSummary.guest?.arroomrates" dropdownOptionsValue="bbrateid" label="Rates"
                [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'" formControlName="bbrateid"
                (change)="toggleRates.emit()" [trailingIconColor]="'color-secondary-text'">
            </cui-input>
        </div>

        <div class="d-flex gap-2  h-48 mt-1 mb-2">
            <div class="flex-1">
                <cui-input type="number" classes="h-48" [sizing]="'md'" label="Adults" class="w-100 me-2 mb-sm-0 me-2"
                    formControlName="noadults" (input)="onInputChange($event)"></cui-input>
            </div>
            <div class="flex-1">
                <cui-input type="number" classes="h-48" [sizing]="'md'"
                    [label]="'Child (' + options?.childDisplay1 + ')'" class="flex-grow-1 ml-2 me-2"
                    formControlName="child1" (input)="onInputChange($event)"></cui-input>
            </div>
            <div class="flex-1">
                <cui-input *ngIf="bookingSummary.establishment.childage2 > 0" type="number" classes="h-48"
                    [sizing]="'md'" [label]="'Child (' + options?.childDisplay2 + ')'"
                    class="flex-grow-1 ml-2 mr-2" formControlName="child2"
                    (input)="onInputChange($event)"></cui-input>
            </div>
        </div>

        <div class="d-flex gap-1 mb-1">
            <div class="d-flex w-100 flex-sm-2">
                <cui-input type="text" classes="h-48" [readonly]="true" [sizing]="'md'" label="Guest" class="w-100 mb-2 mb-sm-0" [internalValue]="bookingSummary.guest?.clientfullname"></cui-input>
            </div>
            <div class="d-flex flex-sm-1 gap-1 flex-row my-1 mb-sm-0">
              <cui-button classes="btn__regular-icon--active-green btn__icon--round-desktop button-ic_person m-0 ms-1 btn-desktop h-40 w-40" mode="light" type="icon-filled" sizing="sm" leadingIcon="person" (click)="editClient.emit(bookingSummary.guest.clientid)" ngbTooltip="{{'bookingSummary' | translate:'guestEdit'}}" tooltipClass="tooltip__container"></cui-button>
              <cui-button classes="btn__regular-icon--active-blue btn__icon--round-desktop button-icon-svg m-0 btn-desktop p-0 h-40 w-40" mode="light" type="icon-filled" sizing="sm" leadingIcon="person-add" (click)="changeGuestClient.emit()" ngbTooltip="Change Guest" tooltipClass="tooltip__container"></cui-button>
              <cui-button classes="btn__regular-icon--active-blue btn__icon--round-desktop button-icon-svg m-0 btn-desktop p-0 h-40 w-40" mode="light" type="icon-filled" sizing="sm" leadingIcon="email" (click)="sendEmail.emit(bookingSummary.guest?.clientemail)" [disabled]="!bookingSummary.guest?.clientemail" ngbTooltip="Email Guest" tooltipClass="tooltip__container"></cui-button>
              <cui-button classes="btn__regular-icon--active-blue btn__icon--round-desktop button-icon-svg m-0 btn-desktop p-0 h-40 w-40" mode="light" type="icon-filled" sizing="sm" leadingIcon="calculator" (click)="viewGuestAccount.emit()" [disabled]="!guestAccountExists"  ngbTooltip="View Guest Account" tooltipClass="tooltip__container"></cui-button>
            </div>
        </div>

        <div class="d-flex gap-1 mb-2">
            <div class="d-flex w-100 flex-sm-2">
                <cui-input type="text" [readonly]="true" classes="h-48" [sizing]="'md'" label="2nd Guest" class="w-100 mb-2 mb-sm-0" placeholder="Guest Name" [internalValue]="bookingSummary.guest?.client2fullname ? bookingSummary.guest?.client2fullname : ''"></cui-input>
            </div>
            <div class="d-flex flex-sm-1 gap-1 flex-row my-1 mt-sm-2 mb-sm-0">
              <cui-button classes="btn__regular-icon--active-green btn__icon--round-desktop button-ic_person m-0 ms-1 btn-desktop h-40 w-40" mode="light" type="icon-filled" sizing="sm" leadingIcon="person" (click)="editClient2.emit(bookingSummary.guest.client2id)" [disabled]="bookingSummary.guest?.client2id === 0" ngbTooltip="{{'bookingSummary' | translate:'guestEdit'}}" tooltipClass="tooltip__container"></cui-button>
              <cui-button classes="btn__regular-icon--active-blue btn__icon--round-desktop button-icon-svg m-0 btn-desktop p-0 h-40 w-40" mode="light" type="icon-filled" sizing="sm" leadingIcon="person-add" (click)="changeGuest2Client.emit()" ngbTooltip="Change Guest" tooltipClass="tooltip__container"></cui-button>
              <cui-button classes="btn__regular-icon--active-blue btn__icon--round-desktop button-icon-svg m-0 btn-desktop p-0 h-40 w-40" mode="light" type="icon-filled" sizing="sm" leadingIcon="email" (click)="sendEmail.emit(bookingSummary.guest?.client2email)" [disabled]="!bookingSummary.guest?.client2email" ngbTooltip="Email Guest" tooltipClass="tooltip__container"></cui-button>
              <cui-button classes="btn__regular-icon--active-blue btn__icon--round-desktop button-icon-svg m-0 btn-desktop p-0 h-40 w-40" mode="light" type="icon-filled" sizing="sm" leadingIcon="remove-filled" (click)="clearGuest2Client.emit()" [disabled]="bookingSummary.guest?.client2id === 0" ngbTooltip="Clear Guest" tooltipClass="tooltip__container"></cui-button>
            </div>
        </div>

        <div class="d-flex mt-3 mb-2 gap-4">
            <div class="me-2 checkbox-line-height" (click)="validateCheckIn.emit()">
                <input class="form-check-input fs-14" type="checkbox" formControlName="checkedin" />
                <label class="form-check-label fs-14">
                    {{'bookingSummary'|translate:'checkedIn'}}
                </label>
            </div>
            <div class="me-2 checkbox-line-height" (click)="validateCheckOut.emit()">
                <input class="form-check-input fs-14" type="checkbox" formControlName='checkedout' />
                <label class="form-check-label fs-14">
                    {{'bookingSummary'|translate:'out'}}
                </label>
            </div>
        </div>

        <div class="d-flex flex-column gap-1 mt-3">
            <div class="d-flex w-100">
                <cui-button *ngIf="!bookingSummary.changeRoomDisabled" (click)="viewExtras.emit()" mode="secondary" classes="btn-secondary rounded fw-semi-bold m-0" class="flex-shrink-1 h-40"> View Extras </cui-button>

                <div *ngIf="guestAccountExists" class="btn__badge btn__badge_responsive">
                    <sup class="btn__badge_responsive--icon badge-info">
                        <svg class="ic ic_bell absoluteCenter">
                            <use href="assets/images/icons/sprite.svg#ic_bell" />
                        </svg>
                    </sup>
                </div>
            </div>
            <div class="d-flex w-100">
                <cui-button (click)="guestCommunications.emit()" mode="secondary" classes="btn-secondary rounded fw-semi-bold m-0" class="flex-shrink-1 h-40 max-h-48"> Guest Communication </cui-button>
            </div>
        </div>
    </form>
</ng-container>
