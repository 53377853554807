import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ConfirmationService} from 'src/app/service/confirmation.service';
import {CredentialService} from "../credential.service";

/**
 * Interceptor to show an alert if a request fails.
 *
 * @export
 * @class ErrorInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private confirmationService: ConfirmationService,
    private credentialService: CredentialService
  ) {
  }

  /**
   * Checks if the returned body indicates a failure on the request made, and shows an alert.
   *
   * @param {HttpRequest<unknown>} request - The outgoing request object to handle.
   * @param {HttpHandler} next - The next interceptor in the chain, or the backend if no interceptors remain in the chain.
   * @return {*}  {Observable<HttpEvent<unknown>>} - An observable of the event stream.
   * @memberof ErrorInterceptor
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((stateEvent) => {
        if (stateEvent instanceof HttpResponse &&
          stateEvent.body?.success === false &&
          stateEvent.body.error?.message) {

          let self = this;

          console.log(stateEvent);

          let isModalSkipped = false;

          if (stateEvent && stateEvent.url) {
            isModalSkipped = stateEvent.url.includes("skipInterceptor=true")
          }

          if (!isModalSkipped) {
            this.confirmationService.showConfirmation({
              title: 'alertReported',
              text: stateEvent.body.error.message,// ToDo: make please contact support if the error persists or reload.
              buttons: [{
                text: 'ok',
                callback: () => {
                  if (stateEvent.body.error.code === 100 || stateEvent.body.error.code === 101) {
                    self.credentialService.logout();
                  }
                }
              }],
              closeCallback(): any {
                if (stateEvent.body.error.code === 100 || stateEvent.body.error.code === 101) {
                  self.credentialService.logout();
                }
              }
            }, {
              backdrop: 'static',
              keyboard: false
            });
          }
        }
        return stateEvent;
      })
    );
  }
}
