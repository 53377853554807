import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PageRouteService } from 'src/app/service/page-route.service';
import { TranslateService } from 'src/app/service/translate.service';
import { EventsService } from 'src/app/service/events.service';
import { ConfirmationService } from 'src/app/service/confirmation.service';
import { PageRouteDataService } from 'src/app/service/page-route-data.service';

@Component({
  selector: 'app-list-events',
  templateUrl: './list-events.component.html',
  styleUrls: ['./list-events.component.scss']
})
export class ListEventsComponent implements OnInit {
  @Input() options;

  events;
  date;

  constructor(
    private activeModal: NgbActiveModal,
    private pageRouteService: PageRouteService,
    private translateService: TranslateService,
    private eventsService: EventsService,
    private confirmationService: ConfirmationService,
    private pageRouteDataService: PageRouteDataService,
  ) { }

  ngOnInit(): void {
    const pageData = this.pageRouteDataService.getData();
    if (!this.options && pageData) {
      this.options = pageData;
    }

    if (!this.options) {
      this.cancel();
    }

    this.events = this.options.events;
    this.date = this.options.date;
  }

  cancel() {
    this.pageRouteService.back();
  }

  addEvent() {
    this.pageRouteService.navigate('/', this.options, ['/events/add'], {
      date: this.date,
      eventid: 0,
    });
  }

  openEventModal(eventid) {
    this.pageRouteService.navigate('/', this.options, ['/events/edit/' + eventid], {
      date: this.date,
      eventid: eventid,
    });
  }

  deleteEvent(eventid) {
    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('listEvents', 'eventDeleteMessage'),
      buttons: [{
        text: 'no',
        class: 'btn__warning',
        callback: () => {}
      }, {
        text: 'yes',
        class: 'btn__green',
        callback: () => {
          this.eventsService.deleteEvent(eventid).subscribe((response: any) => {
            if (response.success) {
              this.eventsService.notifyEventChange();
            }
            if (response.data.errormsg) {
              this.confirmationService.show({
                title: 'alertReported',
                text: response.data.errormsg,
                buttons: [{
                  text: 'ok',
                  callback: () => {}
                }]
              });
            }

            this.cancel();
          });
        }
      }]
    });
  }

}
