import {Component, EventEmitter, Input, OnInit, Output, forwardRef} from '@angular/core';
import {BsDatepickerConfig, BsLocaleService} from 'ngx-bootstrap/datepicker';
import {enGbLocale} from 'ngx-bootstrap/locale';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {DATE_VIEW_MODE, MONTH_YEAR_SELECTED} from '@core/models/enum';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'x-cui-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateSelectorComponent),
      multi: true,
    },
  ],
})
export class DateSelectorComponent implements OnInit, ControlValueAccessor {

  onChange: (value: Date | null) => void = () => { };
  onTouched: () => void = () => { };

  @Input() dateSelected?: Date;
  @Input() dateText?: string;
  @Input() viewMode?: number;
  @Input() readOnly?: boolean;
  @Input() disabled?: boolean;
  @Input() maxDate?: Date;
  @Input() iconColor: string | undefined = '';
  @Output() monthSelectedChange = new EventEmitter();
  @Output() yearSelectedChange = new EventEmitter();
  @Output() dateSelectedChange = new EventEmitter();


  bsConfig?: Partial<BsDatepickerConfig>;
  pickerIsOpen = false;
  placement = 'bottom left' as any;

  constructor(
    private localeService: BsLocaleService) {
    defineLocale('en-gb', enGbLocale);
  }

  ngOnInit() {
    this.localeService.use('en-gb');
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
    });

    if (this.viewMode == DATE_VIEW_MODE.MONTH) {
      this.bsConfig.dateInputFormat = 'MMMM';
      this.dateText = MONTH_YEAR_SELECTED.MONTH;

    } else if (this.viewMode == DATE_VIEW_MODE.YEAR) {
      this.bsConfig.dateInputFormat = 'YYYY';
      this.dateText = MONTH_YEAR_SELECTED.YEAR;
    } else {
      this.bsConfig.dateInputFormat = 'DD/MM/YYYY';
    }
  }

  writeValue(value: Date | null): void {
    if (value) {
      this.dateSelected = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  dateChange(date: Date) {
    if (this.viewMode == DATE_VIEW_MODE.MONTH) {
      this.monthSelectedChange.emit({
        monthSelected: date,
      });
    } else if (this.viewMode == DATE_VIEW_MODE.YEAR) {
      this.yearSelectedChange.emit({
        yearSelected: date,
      });
    } else {
      this.dateSelectedChange.emit({
        dateSelected: date,
      });
    }

    this.onChange(date);
  }

  onShown(container: any) {
    this.pickerIsOpen = true;

    if (this.viewMode == DATE_VIEW_MODE.MONTH) {
      container.monthSelectHandler = (event: any): void => {
        container._store.dispatch(container._actions.select(event.date));
      };
      container.setViewMode('month');
    } else if (this.viewMode == DATE_VIEW_MODE.YEAR) {
      container.yearSelectHandler = (event: any): void => {
        container._store.dispatch(container._actions.select(event.date));
      };
      container.setViewMode('year');
    } else {
      container.daySelectHandler = (event: any): void => {
        container._store.dispatch(container._actions.select(event.date));
      };
      container.setViewMode('day');
    }
  }

  onHidden(): void {
    this.pickerIsOpen = false;
  }
}
