export const ServiceApis = {
  PLACEHOLDER: "placeholder",
  PAYMENT_TAP: "/payment-tap/api/1.0"
}

export const ServiceLocation = {
  PLACEHOLDER: "placeholder"
}

export const BridgeitServices = {
  PLACEHOLDER: "placeholder"
}
