<div [formGroup]="clientForm">
  <div formArrayName="clientextras" *ngFor="let extra of clientextras.controls; let i = index">
    <div class="d-flex flex-column flex-sm-row gap-3 my-3" [formGroupName]="i">

      <div class="flex-1">
        <cui-input type="select" dropdownOptionsLabel="attributetext" [dropdownOptions]="clientAttributes"
          dropdownOptionsValue="attributetype" label="Attribute" placeholder="Select Attribute" formControlName="fieldtype"
          [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'">
        </cui-input>
      </div>

      <div class="flex-1 d-flex gap-2">

        <div class="flex-1">
          <cui-input type="text" label="Value" formControlName="fieldvalue" classes="h-48" sizing="md"
            [maxLength]="30"></cui-input>
        </div>

        <div class="d-flex align-items-center gap-2">
          <xcui-button type="icon" leadingIcon="remove-outlined" classes="btn-danger-secondary" (click)="removeClientExtra.emit(i)"></xcui-button>
          <xcui-button type="icon" leadingIcon="add-outlined" classes="btn-secondary" (click)="addClientExtra.emit()"></xcui-button>
        </div>

      </div>

    </div>
  </div>
</div>
