import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NightsbridgeIconsId} from 'nightsbridge-icons/nightsbridge-icons';

export type ButtonType = 'filled' | 'outlined' | 'text' | 'tonal' | 'close' | 'icon';
export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | '';

@Component({
  selector: 'xcui-button',
  template: `
    <button type="button" [ngClass]="class" [class]="classes" [id]="name" [name]="name" (click)="onClick.emit($event)" [disabled]="disabled">
      <span class="d-flex align-items-center justify-content-center h-100">
        <ng-container *ngTemplateOutlet="icon;context:{icon:leadingIcon}"></ng-container>
        {{label}}
        <ng-content></ng-content>
        <ng-container *ngTemplateOutlet="icon;context:{icon:trailingIcon}"></ng-container>
      </span>
    </button>

    <ng-template #icon let-icon="icon">
      <div *ngIf="icon" class="d-flex" [ngClass]="{ 'mx-2': type !== 'icon' }">
        <svg [class]="iconClass">
          <use [attr.xlink:href]="'assets/images/sprite.svg#' + icon"></use>
        </svg>
      </div>
    </ng-template>
`,
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input()
  mode: 'primary' | 'secondary' | 'success' | 'cancel' | 'danger' | 'warning' | 'info' | 'light' | 'dark' = 'primary';

  @Input()
  type: ButtonType = 'filled';

  @Input()
  sizing: 'small' | 'medium' | 'large' | '' = '';

  @Input()
  leadingIcon: NightsbridgeIconsId | undefined;

  @Input()
  trailingIcon: NightsbridgeIconsId | undefined;

  @Input()
  iconSize: IconSize = '';

  @Input()
  theme: 'light' | 'dark' = 'light';

  @Input()
  label = '';

  @Input()
  aria: string = '';

  @Input()
  classes = '';

  @Input()
  name = '';

  @Input()
  disabled = false;


  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<Event>();

  public get iconClass(): string {
    const baseClass = 'ic';
    switch (this.iconSize) {
      case 'xs':
        return `${baseClass} ic-16`;
      case 'sm':
        return `${baseClass} ic-18`;
      case 'md':
        return `${baseClass} ic-24`;
      case 'lg':
        return `${baseClass} ic-32`;
      default:
        return `${baseClass}`;
    }
  }

  public get class(): string {
    const classList = ['btn'];

    if (!!this.sizing.length) {
      classList.push(`btn-${this.sizing}`);
    }

    switch (this.type) {
      case 'text':
        break;
      case 'icon':
        this.label = "";
        classList.push('btn-icon');
        break;
      case 'close':
        this.aria = "close";
        this.label = "";
        this.leadingIcon = "close";
        this.iconSize = 'md';
        classList.push(`${classList.pop()}-close`);
        break;
      case 'outlined': {
        let outline = 'btn-';
        outline += this.theme === 'light' ? 'outline-primary' : 'outline-light';
        classList.push(outline);
        break;
      }
      case 'tonal': {
        const mode = this.mode === 'primary' ? `btn-secondary` : `btn-${this.mode}`;
        classList.push(mode);
        break;
      }
      default:
        const filled = this.theme === 'light' ? 'btn-primary' : 'btn-light';
        classList.push(filled);
    }

    return classList.join(" ");
  }
}
